// Pour colorier le btn quand clique dessus
// Pour "Tag Positif" , "Tag Négatif" et "Tag Multiple" (plusieurs choix possibles)
const designBtnWhileClickedMultiple = () => {
  const allMultipuleTags = document.querySelectorAll(".custom-btn.btn-statistics.multiple-true-js")
  allMultipuleTags.forEach((btn) => {
    btn.addEventListener('click', (event) => {
			const questionContainer = btn.parentElement.classList.contains("main-container-multiple-choice") ? btn.parentElement : btn.parentElement.parentElement;
			const maxNbAnswers = questionContainer.dataset.max_nb_answers;
			const hasMaxNbAnswers = maxNbAnswers > 0;

			const inputs = questionContainer.querySelectorAll('input[type="checkbox"]')
			let checkedInputsCount = Array.from(inputs).filter(input => input.checked).length;

			if (!hasMaxNbAnswers || (!(checkedInputsCount >= maxNbAnswers) || !btn.classList.contains("not-selected"))) { // allow select
				if (btn.firstElementChild.checked === true) {
					btn.firstElementChild.checked = false
					checkedInputsCount -= 1;
				} else {
					btn.firstElementChild.checked = true
					checkedInputsCount += 1;
				}

				if (btn.classList.contains("statistics-good") === true) {
					btn.classList.toggle("statistics-good-js")
				// } else if (btn.classList.contains("statistics-medium") === true) {
				//   btn.classList.toggle("statistics-medium-js")
				} else if (btn.classList.contains("statistics-bad") === true) {
					btn.classList.toggle("statistics-bad-js")
				} else if (btn.classList.contains("statistics-neutre") === true) {
					btn.classList.toggle("statistics-neutre-js")
				}
				btn.classList.toggle("not-selected")
			}

			if (hasMaxNbAnswers && (checkedInputsCount >= maxNbAnswers)) {
				questionContainer.querySelectorAll(".custom-btn.btn-statistics.multiple-true-js.not-selected").forEach((btn) => {
					btn.classList.add("not-clickable")
				})
				} else {
					questionContainer.querySelectorAll(".custom-btn.btn-statistics.multiple-true-js.not-selected").forEach((btn) => {
						btn.classList.remove("not-clickable")
					})
			}
    })
  })
}

// Pour colorier le btn quand clique dessus
// Pour "Tag Simple", "Tag Satisfaction", "Tag Degrés", "Tag Important" et "Oui Non" (un seul choix possible)
const designBtnWhileClickedSimple = () => {
  const allTagSimple = document.querySelectorAll(".custom-btn.btn-statistics.tag-choix-unique")
  allTagSimple.forEach((btn) => {
    /**
     * Pour choix simple avec le <select>
     */
    if (btn.type === "select-one") {
      btn.addEventListener('change', () => {
        // Si le bouton a une value
        btn.value ? btn.classList.add('statistics-neutre-js') : btn.classList.remove('statistics-neutre-js')
      })
    } else {
      btn.addEventListener('click', (event) => {
        const questionId = btn.attributes.questionid.value

        const tagAlreadyCheckedNeutre = document.querySelectorAll(".custom-btn.btn-statistics.tag-choix-unique.statistics-neutre.statistics-neutre-js" + "[class*='" + parseInt(questionId) + "']")
        const tagAlreadyCheckedGood = document.querySelectorAll(".custom-btn.btn-statistics.tag-choix-unique.statistics-good.statistics-good-js" + "[class*='" + parseInt(questionId) + "']")
        const tagAlreadyCheckedMedium = document.querySelectorAll(".custom-btn.btn-statistics.tag-choix-unique.statistics-medium.statistics-medium-js" + "[class*='" + parseInt(questionId) + "']")
        const tagAlreadyCheckedBad = document.querySelectorAll(".custom-btn.btn-statistics.tag-choix-unique.statistics-bad.statistics-bad-js" + "[class*='" + parseInt(questionId) + "']")

        // simuler le click
        if (btn.firstElementChild.checked === false) {
          btn.firstElementChild.checked = true
        }

        // si un tag était initialement coché, enlever sa classe et la mettre sur le nouveau tag sélectionné, sinon juste mettre la classe sur le nouveau tag
        if (tagAlreadyCheckedNeutre.length === 0 && tagAlreadyCheckedGood.length === 0 && tagAlreadyCheckedMedium.length === 0 && tagAlreadyCheckedBad.length === 0) {
          // btn.classList.add("statistics-neutre-js")
          if (btn.classList.contains("statistics-good") === true) {
            btn.classList.add("statistics-good-js")
          } else if (btn.classList.contains("statistics-medium") === true) {
            btn.classList.add("statistics-medium-js")
          } else if (btn.classList.contains("statistics-bad") === true) {
            btn.classList.add("statistics-bad-js")
          } else if (btn.classList.contains("statistics-neutre") === true) {
            btn.classList.add("statistics-neutre-js")
          }

        } else {
          // tagAlreadyCheckedNeutre[0].classList.remove("statistics-neutre-js")
          if (tagAlreadyCheckedNeutre.length > 0 ) {
            tagAlreadyCheckedNeutre[0].classList.remove("statistics-neutre-js")
          } else if (tagAlreadyCheckedGood.length > 0) {
            tagAlreadyCheckedGood[0].classList.remove("statistics-good-js")
          } else if (tagAlreadyCheckedBad.length > 0) {
            tagAlreadyCheckedBad[0].classList.remove("statistics-bad-js")
          } else if (tagAlreadyCheckedMedium.length > 0) {
            tagAlreadyCheckedMedium[0].classList.remove("statistics-medium-js")
          }

          if (btn.classList.contains("statistics-good") === true) {
            btn.classList.add("statistics-good-js")
          } else if (btn.classList.contains("statistics-medium") === true) {
            btn.classList.add("statistics-medium-js")
          } else if (btn.classList.contains("statistics-bad") === true) {
            btn.classList.add("statistics-bad-js")
          } else if (btn.classList.contains("statistics-neutre") === true) {
            btn.classList.add("statistics-neutre-js")
          }

        }
      })
    }
  })
}

// Pour colorier le btn quand clique dessus
// Pour "Note Un a Dix"
const designBtnWhileClickedNote = () => {
  const allInputNote = document.querySelectorAll(".custom-btn.btn-rating")
  allInputNote.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      const questionId = btn.attributes.questionid.value
      const goodNoteChecked = document.querySelectorAll(".custom-btn.btn-rating.btn-survey-good.statistics-good-js" + "[class*='" + parseInt(questionId) + "']")
      const mediumNoteChecked = document.querySelectorAll(".custom-btn.btn-rating.btn-survey-medium.statistics-medium-js" + "[class*='" + parseInt(questionId) + "']")
      const badNoteChecked = document.querySelectorAll(".custom-btn.btn-rating.btn-survey-bad.statistics-bad-js" + "[class*='" + parseInt(questionId) + "']")

      // si aucune note n'a encore été cochée
      if (goodNoteChecked.length === 0 && mediumNoteChecked.length === 0 && badNoteChecked.length === 0 ) {
        // si c'est un good, lui mettre la classe good, si medium alors mettre medium etc
        if (btn.classList.contains("btn-survey-good") === true) {
          btn.classList.add("statistics-good-js")
        } else if (btn.classList.contains("btn-survey-medium") === true) {
          btn.classList.add("statistics-medium-js")
        } else if (btn.classList.contains("btn-survey-bad") === true) {
          btn.classList.add("statistics-bad-js")
        }

      // si une note a déjà été cochée initialement
      } else {
        // enlever la classe qui permettait de colorier l'ancienne note
        if (goodNoteChecked.length > 0 ) {
          goodNoteChecked[0].classList.remove("statistics-good-js")
        } else if (mediumNoteChecked.length > 0) {
          mediumNoteChecked[0].classList.remove("statistics-medium-js")
        } else if (badNoteChecked.length > 0) {
          badNoteChecked[0].classList.remove("statistics-bad-js")
        }

        // mettre la classe qui coloriele btn-note sur la nouvelle note
        if (btn.classList.contains("btn-survey-good") === true) {
          btn.classList.add("statistics-good-js")
        } else if (btn.classList.contains("btn-survey-medium") === true) {
          btn.classList.add("statistics-medium-js")
        } else if (btn.classList.contains("btn-survey-bad") === true) {
          btn.classList.add("statistics-bad-js")
        }

      }
    })

  })
}

// Faire apparaitre question additionnelle quand la condition est cochée
// Pour "Tag Satisfaction", "Tag Degrés", "Tag Important", "Tag Positif" , "Tag Négatif", "Tag Simple", "Tag Multiple" et "Oui Non"
const displayQuestionAddiIfRightConditionSelected = () => {
  const allBtnTags = document.querySelectorAll(".custom-btn.btn-statistics.question_ini")

  allBtnTags.forEach((btn) => {

    /**
     * Handle display of question_addi if chosing the right condition for select
    */
   if (btn.type === "select-one") {
     btn.addEventListener('change', () => {
        console.log("displayQuestionAddiIfRightConditionSelected")
        const condition = btn.value
        console.log(condition)
        const themeQuestionInitiale = btn.parentElement.classList[0] === "tag-questionnaire-card" ? btn.parentElement.parentElement.classList[0] : btn.parentElement.classList[0]
        console.log(themeQuestionInitiale)
        const allQuestionAddi = document.querySelectorAll(".question_addi." + themeQuestionInitiale)
        console.log(allQuestionAddi)

        let selectedQuestionAddi = [...allQuestionAddi].filter(qAddi => qAddi.dataset.conditionsSimple.includes(condition))
        let otherQuestionsAddi = [...allQuestionAddi].filter(qAddi => !qAddi.dataset.conditionsSimple.includes(condition))

        if (!condition) { // Si la réponse choisie est le placeholders
          selectedQuestionAddi.forEach(question => {
						let classement = question.classList.contains("classement")

            if(!question.classList.contains('hidden')) {
              question.classList.add('hidden')
							if (classement) {
								classementQuestionAddiManageValues(question, false)
							}
            }
          })
        } else if (selectedQuestionAddi.length == 0) { // Si la réponse choisie ne fais pas partie des conditions
          otherQuestionsAddi.forEach(question => {
						let classement = question.classList.contains("classement")

            if(!question.classList.contains('hidden')) {
              question.classList.add('hidden')
							if (classement) {
								classementQuestionAddiManageValues(question, false)
							}
            }
          })
        } else if (selectedQuestionAddi.length > 0) { // Si la réponse choisie fait partie des conditions
					let classement = selectedQuestionAddi[0].classList.contains("classement")

          if (selectedQuestionAddi[0].classList.contains('hidden')) {
              selectedQuestionAddi[0].classList.remove("hidden")
              selectedQuestionAddi[0].classList.add("visible")
              selectedQuestionAddi[0].scrollIntoView({ behavior: "smooth", block: "center", inline: "center"});
							console.log("questtttion show", question)
							if (classement) {
								classementQuestionAddiManageValues(question, true)
							}
          }
          otherQuestionsAddi.forEach(question => {
						classement = question.classList.contains("classement")
            if(!question.classList.contains('hidden')) {
              question.classList.add('hidden')
							if (classement) {
								classementQuestionAddiManageValues(question, false)
							}
            }
          })
        }
      })
    } else {
      btn.addEventListener('click', (event) => {
        console.log("displayQuestionAddiIfRightConditionSelected")
        // const condition = btn.innerText.toLowerCase().replace(/[,\/%\-_<>()&!;'"?\s+]/g,''))
        const condition_temp = btn.dataset.valueTag.toLowerCase().replace(/[,\/%\-_<>()&!;'"?\s+]/g,'').replace(/:/g, '\\:').replace(/\./g, '\\.')
        const condition = `condition${condition_temp}`

        const themeQuestionInitiale = btn.parentElement.classList[0] === "tag-questionnaire-card" ? btn.parentElement.parentElement.classList[0] : btn.parentElement.classList[0]
        let questionAddi = document.querySelector(".question_addi." + themeQuestionInitiale + "." + condition)


        // Patch for the question 'Très important'. By matching the question title to one of the answers and being part of the class. The answer 'Very important' causes conditional questions to always be shown.
        if (themeQuestionInitiale == 'trèsimportant' && questionAddi && !questionAddi.dataset.conditions.includes(condition)) {
          questionAddi = null
        }

        // TODO FAIRE POUR RADIO COMME AVEC CHECKBOX: SI PLUSIQUEUR TAG INITIAL SONT DES CONITIONS D'UNE MEME QUESTION ADDI NE PAS LA TOGGLE A CHAQUE FOIS QU'ON CLIQUE SUR UN AUTRE TAG
        // si c'est radio button, cad un seul choix possible
        if (btn.firstElementChild.type === "radio") {

          if ( questionAddi && questionAddi.classList.contains("hidden") === true) {
						questionAddi.classList.remove("hidden")
						questionAddi.classList.add("visible")
						questionAddi.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"});
						if (questionAddi.classList.contains("classement")) {
							classementQuestionAddiManageValues(questionAddi, true)
						}
          }
          let otherQuestionAddiOfQuestionInitiale = document.querySelectorAll(".question_addi:not(.hidden):not(." + condition + ")." + themeQuestionInitiale)

          if (themeQuestionInitiale == 'trèsimportant') { // Patch for the question 'Très important'.
            questionAddi = document.querySelector(".question_addi." + themeQuestionInitiale + "." + condition)
            if (questionAddi && !questionAddi.dataset.conditions.includes(condition)) {
              otherQuestionAddiOfQuestionInitiale = document.querySelectorAll(".question_addi:not(.hidden)." + themeQuestionInitiale)
            }
          }

          if (otherQuestionAddiOfQuestionInitiale.length > 0) {
            if (otherQuestionAddiOfQuestionInitiale) {
              otherQuestionAddiOfQuestionInitiale.forEach((otherQuestAddi) => {
                otherQuestAddi.classList.add("hidden")

								if (otherQuestAddi.classList.contains("classement")) {
									classementQuestionAddiManageValues(otherQuestAddi, false)
								}
                // décocher les reponses des questions addi répondues, si on décoche finalement le tag conditionnel dans la question initiale
                const btnTagsAnswersAddi = otherQuestAddi.querySelectorAll("input:checked")
                if (btnTagsAnswersAddi.length > 0 ) {
                  btnTagsAnswersAddi.forEach((btn_clicked) => {
                    btn_clicked.checked = false
                    //type tag
                    if (btn_clicked.parentElement.classList.contains("btn-statistics") === true) {
                      btn_clicked.parentElement.classList.remove("statistics-neutre-js", "statistics-good-js", "statistics-medium-js", "statistics-bad-js")
                    // type note
                    } else {
                      btn_clicked.parentElement.nextElementSibling.classList.remove("statistics-neutre-js", "statistics-good-js", "statistics-medium-js", "statistics-bad-js")
                    }
                  })
                // type review
                } else if (questionAddi.querySelector("textarea") ) {
                  questionAddi.querySelector("textarea").value = ""
                }
              })
            }
          }


        // si c'est checkbox button, cad plusieurs choix possibles
        } else if (btn.firstElementChild.type === "checkbox"){
          let anotherTagCheckedOfSameCondition = false
					let classement = false;

          // savoir si un autre Tag ayant la meme question additionnelle que le btn tag actuel est coché ou non (pour savoir si on hide ou show la question addi)
          if (questionAddi) {
            const inputTagCheckedThatHaveSameCondition = JSON.parse(questionAddi.dataset.conditions).filter(condition => document.querySelector("input" + "[class='hidden " + condition + "']" + "[data-question-ini-theme='" + themeQuestionInitiale + "']").checked);
            if (inputTagCheckedThatHaveSameCondition.length > 0) {
              anotherTagCheckedOfSameCondition = true
            }
						classement = questionAddi.classList.contains('classement')
          }

          if (questionAddi && questionAddi.classList.contains("hidden") === true && anotherTagCheckedOfSameCondition === true) {

              questionAddi.classList.remove("hidden")
              questionAddi.classList.add("visible")

							if (classement) {
								classementQuestionAddiManageValues(questionAddi, true)
							}

              questionAddi.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"});
          } else if (questionAddi && questionAddi.classList.contains("hidden") === false && anotherTagCheckedOfSameCondition === false){
            // TODO: c'est aussi un query Selector All non ? comme radio ?
            questionAddi.classList.add("hidden")

						if (classement) {
							classementQuestionAddiManageValues(questionAddi, false)
						}

            // décocher les reponses des questions addi répondues, si on décoche finalement le tag conditionnel dans la question initiale
            const btnTagsAnswersAddi = questionAddi.querySelectorAll("input:checked")
            if (btnTagsAnswersAddi.length > 0 ) {
              btnTagsAnswersAddi.forEach((btn_clicked) => {
                btn_clicked.checked = false
                //type tag
                if (btn_clicked.parentElement.classList.contains("btn-statistics") === true) {
                  btn_clicked.parentElement.classList.remove("statistics-neutre-js", "statistics-good-js", "statistics-medium-js", "statistics-bad-js")
                // type note
                } else {
                  btn_clicked.parentElement.nextElementSibling.classList.remove("statistics-neutre-js", "statistics-good-js", "statistics-medium-js", "statistics-bad-js")
                }

              })
            // type review
            } else if (questionAddi.querySelector("textarea") ) {
              questionAddi.querySelector("textarea").value = ""
            }

          }


        }

      })
    }


  })
}


const classementQuestionAddiManageValues = (element, show) => {
	const inputs = element.querySelectorAll("input[type='number']")

	inputs.forEach((input) => {
		if (show) {
			input.value = input.dataset.tagId;
			} else {
			input.value = null;
		}
	})
}

//Faire apparaître les question_addi lorsque l'on clique sur la bonne condition - Smileys
const displayQuestionAddiWhenConditionClickedSmiley = () => {
  if (document.querySelector('.question_initiale #smiley-survey')) {
    const hiddenQuestion = document.getElementsByClassName('question_addi');

    const smileys = document.querySelectorAll('.question_initiale .smiley-button');

    [...hiddenQuestion].forEach(question => {

      // Find first previous element containing the class 'question_initiale'
      let first;
      let placeholder = question.previousElementSibling;

      while (placeholder) {
        if (placeholder.classList.contains('question_initiale')) {
          first = placeholder;
          break;
        }

        placeholder = placeholder.previousElementSibling;
      }

      // Check if there is a div that contains class 'smiley-container'
      // We are sure to hide or show only question_addi linked to smiley question
      if (first.children[1].children[0].classList.contains('smiley-container')) {

        const conditions = question.classList;

        smileys.forEach(input => {
          input.addEventListener('click', () => {
            let filter = [...conditions].filter(condi => "condition" + input.dataset.smiley == condi);
            if (input.dataset.question_id == question.dataset.question_link_id) {
              if (filter.length > 0) {

                  question.classList.remove('hidden');
                  question.classList.add("visible");
                  question.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"});
              } else {
                question.classList.add('hidden');
              }
            }

          });
        });
      }
    });
  }
};

// Faire apparaitre question additionnelle quand la condition est cochée
// Pour  "Étoiles"
const displayQuestionAddiIfRightStarSelected = () => {
  const allStarsIni = document.querySelectorAll('.star-checkbox.question_ini');
  allStarsIni.forEach(star => {
    star.addEventListener('click', () => {
      const starCondition = star.attributes.conditions.value.split(" ");
      const starConditionInf = starCondition.find(element => element.includes("inf"));
      const starConditionSup = starCondition.find(element => element.includes("sup"));

      const starNote = star.value;
      const themeQuestionInitiale = star.parentElement.classList[0]
      let questionAddiInf = null;
      let questionAddiSup = null;

      if (starConditionInf) {
        questionAddiInf = document.querySelector(".question_addi." + themeQuestionInitiale + ".conditioninf" + starConditionInf.match(/\d{1,2}/)[0]);
      }

      if (starConditionSup) {
        questionAddiSup = document.querySelector(".question_addi." + themeQuestionInitiale + ".conditionsup" + starConditionSup.match(/\d{1,2}/)[0]);
      }

      if (questionAddiInf && questionAddiInf.classList.contains("hidden") === true  && parseInt(starNote) <= parseInt(starConditionInf.match(/\d{1,2}/)[0])) {
          questionAddiInf.classList.remove("hidden");
          questionAddiInf.classList.add("visible")
          questionAddiInf.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"});
        if (questionAddiSup && questionAddiSup.classList.contains("hidden") === false) {
          questionAddiSup.classList.add("hidden");
        }

      } else if (questionAddiSup && questionAddiSup.classList.contains("hidden") === true && parseInt(starNote) >= parseInt(starConditionSup.match(/\d{1,2}/)[0])) {
          questionAddiSup.classList.remove("hidden");
          questionAddiSup.classList.add("visible")
          questionAddiSup.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"});
        if (questionAddiInf && questionAddiInf.classList.contains("hidden") === false) {
          questionAddiInf.classList.add("hidden");
        }

      } else if (questionAddiInf && questionAddiInf.classList.contains("hidden") === false && parseInt(starNote) > parseInt(starConditionInf.match(/\d{1,2}/)[0])) {
        questionAddiInf.classList.add("hidden");

      } else if (questionAddiSup && questionAddiSup.classList.contains("hidden") === false && parseInt(starNote) < parseInt(starConditionSup.match(/\d{1,2}/)[0])) {
        questionAddiSup.classList.add("hidden");
      }
    });
  });
};

// Faire apparaitre question additionnelle quand la condition est cochée
// Pour  "Note Un a Dix"
const displayQuestionAddiIfRigtNoteSelected = () => {
  const allInputNoteIni = document.querySelectorAll(".custom-btn.btn-rating.question_ini")
  allInputNoteIni.forEach((btn) => {
    btn.addEventListener('click', (event) => {

      const noteCondition = btn.attributes.conditions.value.split(" ")
      const noteConditionInf = noteCondition.find(element => element.includes("inf"));
      const noteConditionSup = noteCondition.find(element => element.includes("sup"));

      const btnNote = btn.innerText
      const themeQuestionInitiale = btn.parentElement.classList[0]
      let questionAddiInf = null
      let questionAddiSup = null

      if (noteConditionInf) {
        questionAddiInf = document.querySelector(".question_addi." + themeQuestionInitiale + ".conditioninf" + noteConditionInf.match(/\d{1,2}/)[0])
      }
      if (noteConditionSup) {
        questionAddiSup = document.querySelector(".question_addi." + themeQuestionInitiale + ".conditionsup" + noteConditionSup.match(/\d{1,2}/)[0])
      }

      if (questionAddiInf && questionAddiInf.classList.contains("hidden") === true  && parseInt(btnNote) <= parseInt(noteConditionInf.match(/\d{1,2}/)[0])) {
          questionAddiInf.classList.remove("hidden")
          questionAddiInf.classList.add("visible")
          questionAddiInf.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"});
        if (questionAddiSup && questionAddiSup.classList.contains("hidden") === false) {
          questionAddiSup.classList.add("hidden")
        }

      } else if (questionAddiSup && questionAddiSup.classList.contains("hidden") === true && parseInt(btnNote) >= parseInt(noteConditionSup.match(/\d{1,2}/)[0])) {
          questionAddiSup.classList.remove("hidden")
          questionAddiSup.classList.add("visible")
          questionAddiSup.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"});
        if (questionAddiInf && questionAddiInf.classList.contains("hidden") === false) {
          questionAddiInf.classList.add("hidden")
        }

      } else if (questionAddiInf && questionAddiInf.classList.contains("hidden") === false && parseInt(btnNote) > parseInt(noteConditionInf.match(/\d{1,2}/)[0])) {
        questionAddiInf.classList.add("hidden")

      } else if (questionAddiSup && questionAddiSup.classList.contains("hidden") === false && parseInt(btnNote) < parseInt(noteConditionSup.match(/\d{1,2}/)[0])) {
        questionAddiSup.classList.add("hidden")
      }

    })
  })
}

// Faire apparaitre les questions additionnelles quand la condition est selectionnée.
// Pour "Nationality"
const displayQuestionAddiIfRightNationalitySelected = () => {
  const selectorNationality = document.querySelectorAll('.selector-nationality.question_ini');

  if (selectorNationality) {
    selectorNationality.forEach(selector => {
      selector.onchange = (event) => {
        // Get condition & theme of the initial question.
        let inputText = event.target.value.toLowerCase();
        let themeQuestionInitiale = selector.attributes.theme.value

        // Hidde all additional questions.
        let allAdditionalQuestions = document.querySelectorAll(".question_addi." + themeQuestionInitiale)
        if (allAdditionalQuestions) {
          allAdditionalQuestions.forEach(question => {
            question.classList.add("hidden")
          })
        }

        // Display additional questions with input text as class.
        let additionalQuestions = document.querySelectorAll(".question_addi." + themeQuestionInitiale + ".condition" + inputText)
        additionalQuestions.forEach(question => {
            question.classList.remove("hidden")
            question.classList.add("visible")
            question.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"});

        })
      }
    });
  }
}

// Affiche les questions choix multiples en fonction des sélections de l'utilisateur.
const displayQuestionsMultiple = () => {
  const lotOfQuestion = document.getElementById("lot_of_question");
  if (!lotOfQuestion) return;

  const allQuestions = lotOfQuestion.querySelectorAll('.question_initiale, .question_addi');

  // Révèle l'élément donné en supprimant la classe 'hidden' et en ajoutant la classe 'visible'.
  const revealElement = (element) => {
    console.log('revealElement', element)
    if (element) {
      // scroll question choix multiples avec délai de 2.5s
      // setTimeout(() => {
        element.classList.remove("hidden");
        element.classList.add("visible");
      //   element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"});
      // }, 2500);
    }
  };

  // Vérifie si une sélection a été faite dans l'élément spécifié.
  const isSelectionMade = (element, selector) => element.querySelectorAll(selector).length > 0;

  // Trouve la question suivante à afficher en fonction de la question actuelle.
  const findNextQuestion = (currentQuestion) => {
    const isCurrentConditional = currentQuestion.classList.contains('question_addi');
    let nextSibling = currentQuestion.nextElementSibling;
    while (nextSibling) {
      if (isCurrentConditional && nextSibling.classList.contains('question_initiale')) {
        return nextSibling;
      }
      else if (!isCurrentConditional && (nextSibling.classList.contains('question_initiale') || nextSibling.classList.contains('question_addi'))) {
        return nextSibling;
      }
      nextSibling = nextSibling.nextElementSibling;
    }
    return null;
  };

  // Trouve la question conditionnelle suivante à afficher en fonction de la question actuelle et de l'élément sélectionné.
  const findConditionalQuestion = (question, selectedElement) => {
    const conditionValue = selectedElement ? selectedElement.getAttribute('data-value-tag') : null;
    let nextQuest = question.nextElementSibling;
    let nextSibling = nextQuest.nextElementSibling;
    let foundConditional = false;
    //console.log('nextSibling', nextSibling)
    //console.log('nextQuest', nextQuest)
    const conditions = nextSibling.getAttribute('data-conditions').split(',');
    let isReliedToQuestionIni = false;
    if (conditions.includes(conditionValue)) {
      isReliedToQuestionIni = true;
    }

    while (nextSibling) {
      if (!foundConditional && nextSibling.classList.contains('question_addi') && isReliedToQuestionIni) {
        foundConditional = true;
        console.log('question suivante = question conditionnelle')
        return nextSibling;
      } else if ( !foundConditional && nextSibling.classList.contains('question_addi') && !isReliedToQuestionIni) {
        console.log('question suivante = question initiale')
      } else if (nextSibling.classList.contains('question_initiale') && !foundConditional) {
        console.log('question suivante = question initiale')
          return nextSibling;
      }
      nextSibling = nextSibling.nextElementSibling;
    }
    return null;
  };

  // Lie les événements aux éléments de réponse pour révéler la question suivante lorsque l'utilisateur fait une sélection.
  const bindEvents = (question, selector, eventType, check) => {
    const elements = question.querySelectorAll(selector);
    elements.forEach((element) => {
      element.addEventListener(eventType, () => {
        if (check(element)) {
          let nextElement;
          if (question.classList.contains('question_initiale')) {
            nextElement = findConditionalQuestion(question, element);
          } else {
            nextElement = findNextQuestion(question);
          }
          if (nextElement) {
            revealElement(nextElement);
          }
        }
      });
    });
  };

  // Configure les types de boutons et les types d'événements correspondants.
  const btnTypes = {
    '.custom-btn.btn-statistics.multiple-true-js': 'click',
  };

  // Lie les événements à toutes les questions.
  allQuestions.forEach((question) => {
    Object.entries(btnTypes).forEach(([selector, eventType]) => {
      const condition = selector === '.review_type'
        ? (element) => eventType === 'keyup' && element.value.length > 2
        : (element) => isSelectionMade(question, selector);
      bindEvents(question, selector, eventType, condition);
    });
  });
};

// Affiche les questions de manière séquentielle en fonction des sélections de l'utilisateur.
const displayQuestionsSequentially = () => {
  const lotOfQuestion = document.getElementById("lot_of_question");
  if (!lotOfQuestion) return;

  const allQuestions = lotOfQuestion.querySelectorAll('.question_initiale, .question_addi');

  // Révèle l'élément donné en supprimant la classe 'hidden' et en ajoutant la classe 'visible'.
  const revealElement = (element, addi) => {
    console.log('revealElement', element)
    console.log(addi)
    if (element) {
        element.classList.remove("hidden");
        element.classList.add("visible");
        if (addi === false) {
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"});
        }
    }
  };

  // Vérifie si une sélection a été faite dans l'élément spécifié.
  const isSelectionMade = (element, selector) => element.querySelectorAll(selector).length > 0;

  // Trouve la question suivante à afficher en fonction de la question actuelle.
  const findNextQuestion = (currentQuestion) => {
    const isCurrentConditional = currentQuestion.classList.contains('question_addi');
    let nextSibling = currentQuestion.nextElementSibling;
    console.log('findNextQuestion')
    while (nextSibling) {
      if (isCurrentConditional && nextSibling.classList.contains('question_initiale')) {
        return nextSibling;
      }
      else if (!isCurrentConditional && (nextSibling.classList.contains('question_initiale') || nextSibling.classList.contains('question_addi'))) {
        return nextSibling;
      }
      nextSibling = nextSibling.nextElementSibling;
    }
    return null;
  };

  // Trouve la question conditionnelle suivante à afficher en fonction de la question actuelle et de l'élément sélectionné.
  const findConditionalQuestion = (question, selectedElement) => {
    const conditionValue = selectedElement ? selectedElement.getAttribute('data-value-tag') : null;
    console.log(conditionValue)
    let nextQuest = question.nextElementSibling;
    let nextSibling = nextQuest.nextElementSibling;
    let foundConditional = false;
    console.log('nextSibling', nextSibling)
    console.log('nextQuest', nextQuest)
    let isReliedToQuestionIni = false;
    if (nextSibling.getAttribute('data-conditions')) {
      const conditions = nextSibling.getAttribute('data-conditions').split(',');
      console.log(conditions)
      if (conditions.includes(conditionValue)) {
        isReliedToQuestionIni = true;
        console.log(isReliedToQuestionIni)
      }
    }

    while (nextSibling) {
      if (!foundConditional && nextSibling.classList.contains('question_addi') && isReliedToQuestionIni) {
        foundConditional = true;
        console.log('question suivante = question conditionnelle')
        return nextSibling;
      } else if ( !foundConditional && nextSibling.classList.contains('question_addi') && !isReliedToQuestionIni) {
        console.log('question suivante = question initiale')
      } else if (nextSibling.classList.contains('question_initiale') && !foundConditional) {
        console.log('question suivante = question initiale')
        return nextSibling;
      }
      nextSibling = nextSibling.nextElementSibling;
    }
    return null;
  };


	const handleEvent = (element, question, check, addi) => {
		if (check(element)) {
			let nextElement;
			if (question.classList.contains('question_initiale')) {
				console.log(question)
				nextElement = findConditionalQuestion(question, element);
				console.log(nextElement)
			} else {
				nextElement = findNextQuestion(question);
				console.log(nextElement)
			}
			if (nextElement) {
				if (nextElement.previousElementSibling.previousElementSibling.classList.contains('question_addi')) {
					addi = true;
				}
				console.log(addi)
				revealElement(nextElement, addi);
			}
		}
	};

  // Lie les événements aux éléments de réponse pour révéler la question suivante lorsque l'utilisateur fait une sélection.
	const bindEvents = (question, selector, eventType, check) => {
		const elements = question.querySelectorAll(selector);
		let addi = false;
		elements.forEach((element) => {

			// For Select2 events, uses jQuery's 'on' method. Vanilla does not work
			if (eventType.startsWith('select2')) {
				$(element).on(eventType, () => {
					console.log(eventType)
					handleEvent(element, question, check, addi);
				});
			} else {
				element.addEventListener(eventType, () => {
					console.log(eventType)
					handleEvent(element, question, check, addi);
				});
			}
		});
	};

	const btnTypes = {
		'.custom-btn.btn-statistics.tag-choix-unique': 'click',
		'.custom-btn.btn-rating': 'click',
		'.review_type': 'keyup',
		'.smiley-button': 'click',
		'.star-checkbox.question_ini': 'click',
		'[id^="nationality_for_"]': 'select2:select',
	};


	// Bind events to all questions
	allQuestions.forEach((question) => {
		Object.entries(btnTypes).forEach(([selector, eventType]) => {
			const condition = selector === '.review_type'
				? (element) => eventType === 'keyup' && element.value.length > 2
				: (element) => isSelectionMade(question, selector);
			bindEvents(question, selector, eventType, condition);
		});
	});


};


const removeNotConcernedTextOnInputChange = () => {

	let radioButtons = document.querySelectorAll('input[type="radio"]');
	let checkBoxes = document.querySelectorAll('input[type="checkbox"]');
	let textAreas = document.querySelectorAll('textarea');
	let notConcernedText = document.querySelector(".new-survey-text-not-concerned-js")

	Array.prototype.forEach.call(document.querySelectorAll("input"), input => {

		input.parentNode.addEventListener("click", () => {
			let value_not_nil = ((Array.prototype.some.call(radioButtons, radioButton => radioButton.checked))
			|| (Array.prototype.some.call(checkBoxes, checkBoxe => checkBoxe.checked))
			|| Array.prototype.some.call(textAreas, textArea => (textArea.value != null && textArea.value != '')));

      if(notConcernedText) {
        if (value_not_nil == true) {
          if (!notConcernedText.classList.contains("transparent-text-not-concerned")) {
            notConcernedText.classList.add('transparent-text-not-concerned')
          }
        } else {
          if (notConcernedText.classList.contains("transparent-text-not-concerned")) {
            notConcernedText.classList.remove('transparent-text-not-concerned')
          }
        }
      }
		})
	})

	Array.prototype.forEach.call(document.querySelectorAll("textarea"), input => {

		input.addEventListener("keyup", () => {
			let value_not_nil = ((Array.prototype.some.call(radioButtons, radioButton => radioButton.checked))
			|| (Array.prototype.some.call(checkBoxes, checkBoxe => checkBoxe.checked))
			|| Array.prototype.some.call(textAreas, textArea => (textArea.value != null && textArea.value != '')));

       if (notConcernedText) {
			  if (value_not_nil == true) {
          if (!notConcernedText.classList.contains("transparent-text-not-concerned")) {
            notConcernedText.classList.add('transparent-text-not-concerned')
          }
        } else {
          if (notConcernedText.classList.contains("transparent-text-not-concerned")) {
            notConcernedText.classList.remove('transparent-text-not-concerned')
          }
        }
      }
		})
	})
}

const allJsForQuestionnaireNewSurveyV2 = () => {
  if (document.getElementById("new-survey-v2-for-js")) {

    //Designer les Tags quand on clique dessus pour les Tags à plusieurs réponses possibles, à choix unique et note
    designBtnWhileClickedMultiple();
    designBtnWhileClickedSimple();
    designBtnWhileClickedNote();

    // Faire apparaitre les questions additionnelles quand on coche un Tag/Note étant reliée à une question additionnelle
    displayQuestionAddiIfRightConditionSelected();
    displayQuestionAddiIfRigtNoteSelected();
    displayQuestionAddiIfRightStarSelected();
    displayQuestionAddiWhenConditionClickedSmiley();
    displayQuestionAddiIfRightNationalitySelected();

    // Faire apparaitre les questions initiales et conditionnelles une par une (quand on repond à une question)
    displayQuestionsSequentially();
    displayQuestionsMultiple();

		// Enlever ou remettre le text 'not-concerned' quand on repond à une question
		removeNotConcernedTextOnInputChange();

    // quand un tag dont le content est 'Autre' a été séléctionné, afficher le text_area pour préciser la réponse pour les questions Tag Positif, Negatif et Neutre
    document.querySelectorAll(".tag-type-autre").forEach((tag) => {
      tag.addEventListener('click', (event) => {
        if (event.currentTarget.firstElementChild.checked) {
          const otherInputDiv = event.currentTarget.nextElementSibling || event.currentTarget.parentElement.nextElementSibling
          otherInputDiv.classList.remove("hidden")
        } else {
          event.currentTarget.closest(".container-additional-question-answers").querySelectorAll(".other-tag-survey").forEach(element => {
            element.classList.add("hidden");
          });
        }
      })
    })

    // quand un tag dont le content est 'Autre' a été séléctionné, afficher le text_area pour préciser la réponse quand tag Oui Non ou Tag Choix simple
    document.querySelectorAll(".tag-simple-for-autre-js").forEach((tag) => {
      tag.addEventListener('click', (event) => {
        if (event.currentTarget.querySelector("input").checked && ["autre", "autres", "autrechoix", "autreschoix", "altro", "altrascelta", "alternativa", "other", "otherchoice", "alternative", "آخر", "الآخرين", "خيار آخر", "خيارات اخرا", "Другие", "другие", "другойвыбор", "альтернатива", "outro" ,"outras" ,"outraescolha" ,"alternativo", "басқа", "басқалар", "басқатаңдау", "балама", "outros", "outra", "andere", "anderewahl", "anderewahlmöglichkeiten", "бусад", "өөрсонголт", "бусадсонголтууд"].includes(event.currentTarget.querySelector(".dopplelabel").innerText.toLowerCase().replace(/\s+/g, '')) ) {
          const otherInputDiv = event.currentTarget.nextElementSibling || event.currentTarget.parentElement.nextElementSibling
          otherInputDiv.classList.remove("hidden")
        } else {
          event.currentTarget.closest(".oui-non-tag-unique-js").querySelectorAll(".other-tag-survey").forEach(element => {
            element.classList.add("hidden");
          });
        }
      })
    })
  }

    //quand on appuie sur le btn "suivant" pour un thème de question, afficher les questions encore cachées qui n'ont pas eu de réponse avant de passer au thème suivant
    let fakeBtnValidate = document.querySelector(".fake-btn-for-validate-survey-js")
    let realBtnValidate = document.querySelector(".btn-valide-question")

    if (fakeBtnValidate) {
      fakeBtnValidate.addEventListener("click", (event) => {
        if (document.querySelectorAll(".question_initiale:not(.hidden)").length > 0) {
          document.querySelectorAll(".question_initiale.hidden").forEach((question) => {
            question.classList.remove("hidden")
          })
        }
        fakeBtnValidate.classList.add("hidden")
        realBtnValidate.classList.remove("hidden")
      })

      document.querySelector("form").addEventListener("click", (event) => {
        if (document.querySelectorAll(".question_initiale.hidden").length === 0) {
          fakeBtnValidate.classList.add("hidden")
          realBtnValidate.classList.remove("hidden")
        }
      })
    }



		const form = document.querySelector(".new_survey_v2_form");

		if (form) {
			form.addEventListener("submit", function(event) {

				if (!(document.querySelector(".btn-valide-question").classList.contains("ignore-required"))) { // ignore-required in custom

					let isValid = true;
					let firstInvalidElement = null;

					document.querySelectorAll('.required-question').forEach(function(questionElement) {
						let isAnswered = false;
						const inputElements = questionElement.querySelectorAll('input, textarea, select');

						inputElements.forEach(function(inputElement) {
							// Handle text inputs (including textareas)
							if ((inputElement.type === 'text' || inputElement.tagName.toLowerCase() === 'textarea') && inputElement.value.trim() !== "") {
								isAnswered = true;
							}
							// Handle checkboxes and radios
							else if ((inputElement.type === 'checkbox' || inputElement.type === 'radio') && inputElement.checked) {
								isAnswered = true;
							}
							// Handle select elements
							else if (inputElement.tagName.toLowerCase() === 'select' && inputElement.value.trim() !== "") {
								isAnswered = true;
							}
						});

						if (!isAnswered) {
							isValid = false;
							questionElement.classList.add('unanswered'); // Add a class to style it as red
							if (!firstInvalidElement) {
								firstInvalidElement = questionElement;
							}
						} else {
							questionElement.classList.remove('unanswered'); // Remove the red style if corrected
						}
					});

					if (!isValid) {
						event.preventDefault(); // Stop form submission
						if (firstInvalidElement) {
							firstInvalidElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
							firstInvalidElement.focus(); // Focus on the first invalid element

							firstInvalidElement.focus(); // Focus on the first invalid element

							// Add zoom effect
							console.log("firstInvalidElement.children", firstInvalidElement.children)
							Array.from(firstInvalidElement.children).forEach(function(e) {
								e.classList.add('zoom');
							});

							// Remove zoom class after animation completes (1.5 seconds as set in CSS)
							setTimeout(() => {
								Array.from(firstInvalidElement.children).forEach(function(e) {
									e.classList.remove('zoom');
								});
								// firstInvalidElement.classList.remove('zoom');
							}, 1500);
						}
					}
				}

			});
		}



 }

export {allJsForQuestionnaireNewSurveyV2}
