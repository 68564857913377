import { showBanner, setBanner } from './_flash_banner';

export const groupeQuestionDescription = () => {
	console.log("groupeQuestionDescription");
	const $banner = setBanner();
	
	const formDiv = document.getElementById('description-form-div');
	const toggleBtn = document.getElementById('toggle-description-form')
	const closeDivBtn = document.querySelector('.close-description-btn')
	const deleteDescBtn = document.querySelector('.delete-description-btn')

	if (formDiv) {
		console.log("formDiv")
		const form = document.getElementById("description-form-form")
		const sumbitBtn = formDiv.querySelector(".submit-btn")

		const toggleForm_ft = () => {
			if (formDiv.classList.contains('d-none')) {
				formDiv.classList.remove("d-none")
				toggleBtn.classList.add("d-none")
			} else {
				formDiv.classList.add("d-none")
				toggleBtn.classList.remove("d-none")
			}
		}

		const submitForm = () => {
			// event.preventDefault(); 
			console.log("form clic")
	
			const formData = new FormData(form);
	
			fetch(form.action, {
				method: form.method,
				headers: {
					'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
				},
				body: formData,
			})
			.then(response => response.json()) // Assuming your controller returns JSON
			.then(data => {
				if (data.success) {
					if ($banner) {
						showBanner($banner.attr("data-edit-success"), "success");
					}
					//alert('Description saved successfully!');
					console.log("description", data.description)
					if (data.description) {
						toggleBtn.classList.add("d-none")
						formDiv.classList.remove("d-none")
						
						document.querySelector(".description-div-collapse").click()
						document.querySelector(".no-description").classList.add("d-none")
						document.querySelector(".with-description").classList.remove("d-none")
					} else {
						toggleBtn.classList.remove("d-none")
						formDiv.classList.add("d-none")
						document.querySelector("#description-div-content").classList.add("show")
						document.querySelector(".no-description").classList.remove("d-none")
						document.querySelector(".with-description").classList.add("d-none")
					}

				} else {
					// Handle validation errors or empty description
					if (data.errors && data.errors.description) {
						alert('Please fill out the description fields.');
					} else {
						alert('Description was submitted empty. Please consider adding content.');
					}
				}
			})
			.catch(error => {
				console.error('Error:', error);
				alert('There was an error saving the description. Please try again.');
			});
		}

		closeDivBtn.addEventListener('click', function() {
			toggleForm_ft();
		});

		toggleBtn.addEventListener('click', function() {
			console.log("toggleBtn")
			toggleForm_ft();
		});

		deleteDescBtn.addEventListener('click', function() {

			Swal.fire({
        title: deleteDescBtn.dataset.lang === 'fr' ? 'Êtes-vous sûr de vouloir supprimer cette description ?' : "Are you sure you want to delete this description?",
        text: deleteDescBtn.dataset.lang === 'fr' ? 'Cette action est irréversible' : 'This action is irreversible',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: deleteDescBtn.dataset.lang === 'fr' ? 'Oui, supprimer' : "Yes, remove" ,
        cancelButtonText: deleteDescBtn.dataset.lang === 'fr' ? 'Annuler' : "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
					form.querySelectorAll('input.description-input, textarea.description-input').forEach(input => {
						input.value = '';
					});
					submitForm();
				}})
		});

		sumbitBtn.addEventListener('click', function (event) {
			submitForm();
		});
	}

}

