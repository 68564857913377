export function chartComparisonCsat() {
	const csatComparisonRestaurantsGq = document.querySelectorAll("#csat-comparison-restaurants-gq");
  Array.prototype.forEach.call(csatComparisonRestaurantsGq, function (csatComparison) {
    if (csatComparison) {
      console.log("csatComparison")

      let restaurantLabels = JSON.parse(csatComparison.dataset.restaurants).filter(label => !label.endsWith("- "));

      const csat_per_restaurant_gq = new Chart(csatComparison, {
        type: 'horizontalBar',
        data: {
          labels: restaurantLabels,
          datasets: [{
            label: JSON.parse(csatComparison.dataset.labels_repartition)[0],
            data: JSON.parse(csatComparison.dataset.very_detractor).slice(0, restaurantLabels.length),
            backgroundColor: '#FA0000',
            yAxisID: 'left-y-axis'
          }, {
            label: JSON.parse(csatComparison.dataset.labels_repartition)[1],
            data: JSON.parse(csatComparison.dataset.detractor).slice(0, restaurantLabels.length),
            backgroundColor: '#fd397a',
            yAxisID: 'left-y-axis'
          }, {
            label: JSON.parse(csatComparison.dataset.labels_repartition)[2],
            data: JSON.parse(csatComparison.dataset.neutral).slice(0, restaurantLabels.length),
            backgroundColor: '#ffb822',
            yAxisID: 'left-y-axis'
          }, {
            label: JSON.parse(csatComparison.dataset.labels_repartition)[3],
            data: JSON.parse(csatComparison.dataset.promotor).slice(0, restaurantLabels.length),
            backgroundColor: '#1dd18f',
            yAxisID: 'left-y-axis'
          }, {
            label: JSON.parse(csatComparison.dataset.labels_repartition)[4],
            data: JSON.parse(csatComparison.dataset.very_promotor).slice(0, restaurantLabels.length),
            backgroundColor: '#1dc9b7',
            yAxisID: 'left-y-axis'
          }
          ]
        },

        options: {
          indexAxis: 'y',
          elements: {
            bar: {
              borderWidth: 2,
            }
          },
          maintainAspectRatio: false,
          plugins: {
            datalabels: {
              formatter: function(value, ctx) {
                let percentage = Math.round(value);
                return percentage >= 3 ? percentage + "%" : '';
              },
              color: '#fff',
              display: function(context) {
                return context.dataset.data[context.dataIndex] !== 0;
              }
            }
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
                const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || 0;
                if (value !== 0) {
                  return datasetLabel + ': ' + Math.round(value) + '%';
                }
              },
            },
          },
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              stacked: true,
              offset: true,
              ticks: {
                min: 0,
                max: 100,
              },
              display: false, // Hide x-axis scale
              gridLines: {
                display: false
              },
            }],
            yAxes: [{
              id: 'left-y-axis',
              ticks: {
                display: true,
              },
              stacked: true,
              offset: true,
              gridLines: {
                display: false,
              }
              }]
          },
        }
      });
      csat_per_restaurant_gq.update();

      let chart = csat_per_restaurant_gq
      chart.options.animation = false;


    }
  });
}
