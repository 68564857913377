import { displayCropper } from './_handleCropper'
import { logoBkgColorMenu } from './custom_menu/logoBkgColorMenu'
import { manageFlagsMenu } from './custom_menu/manageFlagsMenu'
import { manageTagsMenu } from './custom_menu/manageTagsMenu'
import { manageFonts } from './custom_questionnaire/manageFonts'

//METHODE utilisée dans customMenu() : pour afficher l'image chargé en background_image dans MY_MENU & START_PAGE_MENU: methode utilisé dans CustomMenu
const displayPreview = (input) => {
	const previewDiv = document.querySelector("iframe#preview")
  if (input.files && input.files[0]) {
    console.log('displayPreview')
    const reader = new FileReader();
    reader.onload = (event) => {
      if (previewDiv.contentDocument.getElementById("page-start-menu-for-js")) {
        console.log("from page start menu")
        previewDiv.contentDocument.querySelector(".links-menu-banner").style.backgroundImage = `url(${event.currentTarget.result})` ;
      } else if (previewDiv.contentDocument.getElementById("page-my-menu-for-js")) {
        previewDiv.contentDocument.querySelector(".new-menu-banner").style.backgroundImage = `url(${event.currentTarget.result})` ;
      }
    }
    reader.readAsDataURL(input.files[0])
  }
}

const changeColorOfSeveralElement = () => {
	const previewDiv = document.querySelector("iframe#preview")
  console.log("changeColorOfSeveralElement")
  const fontColors = document.querySelectorAll("#fontColor, #col_line_dmeal");
  let newColor = "rgb(225, 198, 151)"
  console.log(newColor)
  fontColors.forEach((fontColor) => {
    fontColor.addEventListener("change", (event) => {
        // DETAIL_MENU_NEW page
        if (previewDiv.contentDocument.getElementById("id-for-custom-menu-js")) {
          previewDiv.contentDocument.getElementById("categ-tabs").style.boxShadow = `0px 2px 3px 0px ${event.currentTarget.value}`
          previewDiv.contentDocument.getElementById("categ-tabs").style.borderBottomColor = event.currentTarget.value
          previewDiv.contentDocument.querySelectorAll(".btn-detail-menu-js").forEach((detail) => {detail.style.backgroundColor = event.currentTarget.value})
          previewDiv.contentDocument.querySelectorAll(".individual-price-js").forEach((price) => {price.style.color = event.currentTarget.value})
          previewDiv.contentDocument.querySelectorAll(".price-content-menu").forEach((prices) => {prices.style.color = event.currentTarget.value})

          // et les values directement dans les inputs
          document.getElementById("col_separator").value = event.currentTarget.value;
          document.getElementById("col_btn_detail").value = event.currentTarget.value;
          //TODO Price aussi

        } else if (previewDiv.contentDocument.getElementById("page-detail-meal-for-js")) {
          previewDiv.contentDocument.querySelector(".container-pdct-desc-menu-detail-js").style.borderLeft = `3px solid ${event.currentTarget.value}`;
        }

      console.log(`0px 2px 3px 0px ${event.currentTarget.value}`)
    })
  })
}

// METHODE utilisée dans customQuestionnaire() : faire la simulation sur la partie de gauche du font style sur "I, B, S" pour montrer quand c'est coché/décoché
const simulateFontStyleLeftPart = (btn) => {
	const previewDiv = document.querySelector("iframe#preview")
  // partie gauche: le btn a un fond gris quand il est clické + changer en true/false la valeur du hidden_field_tag associé pour le back
  btn.classList.toggle("style-selected")
  let hidden_fiel_tag_for_back = btn.nextElementSibling
  console.log(hidden_fiel_tag_for_back)
  hidden_fiel_tag_for_back.value = hidden_fiel_tag_for_back.value === 'false'

  // si c'est un btn I,B,S pour l'ensemble des texte "all" => l'appliquer dans ce cas egalement à tous les I,B,S spécifique de la page
  if (btn.classList.contains("style-font-police-for-all-texts")) {
    // mettre tous les I,B,S existants sur la page pour les elements specifc également en coché/décoché, sauf le event.currentTarget
    document.querySelectorAll(`.style-font-police-for-specific-texts.${btn.dataset.page}#${btn.id}`).forEach((btnStyle) => {
      if (btnStyle.dataset.page === btn.dataset.page) {
        console.log("same page for")
        console.log(btnStyle)
        if (btn.classList.contains("style-selected")) {
          btnStyle.classList.add("style-selected")
        } else {
          btnStyle.classList.remove("style-selected")
        }

        btnStyle.nextElementSibling.value = hidden_fiel_tag_for_back.value
      }
    })
  }

  // partie gauche: pour les btn BOLD: il faut que ca décoche automatiquement un autre si on en coche un nouveau
  if (btn.id.includes("weight")) {
    document.querySelectorAll("#weight-regular, #weight-medium, #weight-bold").forEach((bold_style) => {
      if (btn.id !== bold_style.id ) {
        bold_style.classList.remove("style-selected")
        bold_style.nextElementSibling.value = "false"
      }
      if (previewDiv) {
        previewDiv.contentDocument.querySelectorAll(".btn-mastercateg-menu").forEach((link) => {
          console.log(bold_style.id)
          link.classList.remove(`${bold_style.id}`)
        })
      }
    })
  }
}


const customMenu = () => {
	if (document.getElementById("custom-menu-for-js")) {
		console.log("customMenu")
		const previewDiv = document.querySelector("iframe#preview")

    const styleFontTexts = document.querySelectorAll(".style-font-police-for-all-texts");
    styleFontTexts.forEach((btn) => {
      btn.addEventListener('click', (event) => {
        console.log(event.currentTarget)
        simulateFontStyleLeftPart(event.currentTarget)

        function selectTextToApplyStyle(className, style) {
          previewDiv.contentDocument.querySelectorAll(`.${className}`).forEach((text) => {
            text.classList.toggle(style)
          })
        }
        // simulation sur iframe
        if (event.currentTarget.id === "italic") {
          selectTextToApplyStyle("btn-link-page-menu-title", "italic-style")
          selectTextToApplyStyle("btn-mastercateg-menu-content", "italic-style")
        } else if (event.currentTarget.id === "weight-regular") {
          previewDiv.contentDocument.querySelectorAll(".btn-link-page-menu-title").forEach((link) => {
            ["bold-style", "medium-style"].forEach((bold_style) => {
              link.classList.remove(bold_style)
            })
            link.classList.toggle("regular-style")
          })

          previewDiv.contentDocument.querySelectorAll(".btn-mastercateg-menu-content").forEach((link) => {
            ["bold-style", "medium-style"].forEach((bold_style) => {
              link.classList.remove(bold_style)
            })
            link.classList.toggle("regular-style")
          })
        } else if (event.currentTarget.id === "weight-medium") {
          previewDiv.contentDocument.querySelectorAll(".btn-link-page-menu-title").forEach((link) => {
            ["regular-style", "bold-style"].forEach((bold_style) => {
              link.classList.remove(bold_style)
            })
            link.classList.toggle("medium-style")
          })

          previewDiv.contentDocument.querySelectorAll(".btn-mastercateg-menu-content").forEach((link) => {
            ["regular-style", "bold-style"].forEach((bold_style) => {
              link.classList.remove(bold_style)
            })
            link.classList.toggle("medium-style")
          })
        } else if (event.currentTarget.id === "weight-bold") {
          previewDiv.contentDocument.querySelectorAll(".btn-link-page-menu-title").forEach((link) => {
            ["regular-style", "medium-style"].forEach((bold_style) => {
              link.classList.remove(bold_style)
            })
            link.classList.toggle("bold-style")
          })

          previewDiv.contentDocument.querySelectorAll(".btn-mastercateg-menu-content").forEach((link) => {
            ["regular-style", "medium-style"].forEach((bold_style) => {
              link.classList.remove(bold_style)
            })
            link.classList.toggle("bold-style")
          })
        } else if (event.currentTarget.id === "underline") {
          selectTextToApplyStyle("btn-link-page-menu-title", "underline-style")
          selectTextToApplyStyle("btn-mastercateg-menu-content", "underline-style")
        }
      })
    })


      //START_PAGE_MENU & MY_MENU: photo à charger en background dans start_page_menu et my_menu
      const inputs = document.querySelectorAll('#bkg_img_mm, #bkg_img_spm');
      if (inputs.length !== 0) {
        console.log('previewImageOnFileSelect')
        // we add a listener to know when a new picture is uploaded
        inputs.forEach((input) => {

          // Remove old value of input
          input.addEventListener('click', () => {
            if (input.value) {
              input.value = ''
            }
          })

          input.addEventListener('change', () => {

            const fileSize = (input.files[0].size) / 1000
            if (fileSize > 400) {
              // Get url to see if the page is in french or english
              const url = new URL(window.location.href).toString()
              const isEnglishRegex = /(\/en\/)/
              const isEnglish = url.match(isEnglishRegex) ? true : false

              if (isEnglish) {
                alert("Your image's size can't exceed 400ko. You can reduce it on this free website https://shortpixel.com/")
              } else {
                alert("Votre image ne peut dépasser 400ko. Vous pouvez la réduire en vous rendant sur le site gratuit https://shortpixel.com/")
              }
            } else {
              let backgroundImageDiv;
              switch (input.id) {
                case "bkg_img_mm":
                  // store url of background image before it's changed
                  backgroundImageDiv = previewDiv.contentDocument.querySelector(".new-menu-banner")
                  // custom survey page
                  if (backgroundImageDiv) {
                    displayCropper(input, backgroundImageDiv);
                  }
                  break;

                default:
                  // we call the displayPreview function (who retrieve the image url and display it)
                  // displayPreview(input);
                  backgroundImageDiv = previewDiv.contentDocument.querySelector(".links-menu-banner")
                  displayCropper(input, backgroundImageDiv);
                  break;
              }
            }
          })
        })
      }


      //START_PAGE_MENU: changer couleur des btn des liens
      const colorBtnsLinks = document.getElementById("bkg_col_link_sp")
      if (colorBtnsLinks) {
        colorBtnsLinks.addEventListener("change", (event) => {
          console.log(event.currentTarget.value);
          previewDiv.contentDocument.querySelectorAll(".btn-link-page-menu").forEach((btn) => {
            btn.style.backgroundColor = event.currentTarget.value
          })
        })
      }

      //START_PAGE_MENU: checkbox for border on btns link or not
      const checkboxBorder = document.getElementById("border");
      const borderCss = document.getElementById("css-for-border")
      if (checkboxBorder) {
        checkboxBorder.addEventListener("change", (event) => {
          if (event.currentTarget.checked) {
            borderCss.classList.remove("hidden")
            previewDiv.contentDocument.querySelectorAll(".btn-link-page-menu").forEach((btn) => {
              btn.style.border = "1px solid black"
            })
          } else {
            borderCss.classList.add("hidden")
            previewDiv.contentDocument.querySelectorAll(".btn-link-page-menu").forEach((btn) => {
              btn.style.border = ""
              btn.style.borderRadius = "3px"
            })
          }
        })
      }

      //START_PAGE_MENU: color on border of btn links
      const colorBorder = document.getElementById("border_col_link_sp")
      if (colorBorder) {
        colorBorder.addEventListener('change', (event) => {
          previewDiv.contentDocument.querySelectorAll(".btn-link-page-menu").forEach((btn) => {
            btn.style.borderColor = event.currentTarget.value
          })
        })
      }


      //START_PAGE_MENU: weight of  border of btn links
      const weightBorder = document.getElementById("border_weight_link_sp")
      if (weightBorder) {
        weightBorder.addEventListener('change', (event) => {
          console.log("change border width")
          previewDiv.contentDocument.querySelectorAll(".btn-link-page-menu").forEach((btn) => {
            btn.style.borderWidth = `${event.currentTarget.value}px`
          })
        })
      }


      //START_PAGE_MENU: border radius of btns: chose one of the 2 options
      const radiusBorders = document.querySelectorAll("[id^='border_radius_link_sp']")
      if (radiusBorders) {
        radiusBorders.forEach((radio) => {
          radio.addEventListener('change', (event) => {
            console.log(event.currentTarget)
            const checkedOne = document.querySelector("[id^='border_radius_link_sp']:checked").value
            previewDiv.contentDocument.querySelectorAll(".btn-link-page-menu").forEach((btn) => {
              btn.style.borderRadius = checkedOne
            })
          })
        })
      }



      //MY_MENU: changer couleur des btn des mastercateg
      const colorBtns = document.getElementById("bkg_col_mascat_card_mm")
      if (colorBtns) {
        colorBtns.addEventListener("change", (event) => {
					console.log(event.currentTarget.value);
          previewDiv.contentDocument.querySelectorAll(".btn-mastercateg-menu").forEach((btn) => {
            btn.style.backgroundColor = event.currentTarget.value
          })
        })
      }


      //MY_MENU: changer couleur du text des btn des mastercateg
      const colorTextBtns = document.getElementById("font_col_mascat_mm")
      colorTextBtns.addEventListener("change", (event) => {
        console.log(event.currentTarget.value);
        previewDiv.contentDocument.querySelectorAll(".btn-mastercateg-menu").forEach((btn) => {
          btn.style.color = event.currentTarget.value
        })
      })



      //START_PAGE_MENU / MY_MENU / DETAIL_MENU_NEW / DETAIL_MEAL_MENU : background-color
      const colorInputs = document.querySelectorAll("[class^='colorWell']")
      colorInputs.forEach((colorInput) => {
        colorInput.addEventListener("change", (event) => {
          console.log(event.currentTarget.value);
          const divToChange = `.${event.currentTarget.dataset.forBackground}`
          previewDiv.contentDocument.querySelectorAll(divToChange).forEach((div) => {
            div.style.backgroundImage = "" //quand c'est my_menu il faut enlever le background-image si on veut mettre un background color a la place
            div.style.backgroundColor = event.currentTarget.value
          })
        })
      })

      // DETAIL_MENU_NEW: background-color of tab-active
      const colorTabActive = document.getElementById("bkg_col_tab_active")
      colorTabActive.addEventListener("change", (event) => {
        console.log(event.currentTarget);
        const divToChange = `.${event.currentTarget.dataset.forBackground}`
        previewDiv.contentDocument.querySelectorAll(divToChange).forEach((div) => {
          div.style.setProperty("background-color", event.currentTarget.value, "important");
        })
      })

      // DETAIL_MENU_NEW: font-color of tab-active
      const fontColorTabActive = document.getElementById("font_col_tab_active")
      fontColorTabActive.addEventListener("change", (event) => {
        console.log(event.currentTarget);
        const elemToChange = `.${event.currentTarget.dataset.forFontColor}`
        previewDiv.contentDocument.querySelectorAll(elemToChange).forEach((elem) => {
          elem.style.setProperty("color", event.currentTarget.value, "important");
        })
      })

      // DETAIL_MENU_NEW: font-color of tabs
      const fontColorTabs = document.getElementById("font_col_tabs")
      fontColorTabs.addEventListener("change", (event) => {
        console.log(event.currentTarget.value);
        previewDiv.contentDocument.querySelectorAll(".cat-tabs:not(.active) a").forEach((div) => {
          div.style.setProperty("color", event.currentTarget.value, "important");
        })
      })

      // DETAIL_MEAL_MENU: Color of the title
      const colorInput = document.getElementById("font_col_title_product_custom_detail_meal_menu")
      colorInput.addEventListener("change", (event) => {
        console.log(event.currentTarget.value);
        previewDiv.contentDocument.querySelector(".title-product-detail-menu-for-js").style.color = event.currentTarget.value
      })


      //START_PAGE_MENU / DETAIL_MENU_NEW / DETAIL_MEAL_MENU : btn color all :  pour changer tous les fontColor de tous les textes de la page
      document.querySelectorAll(".colorFontAll").forEach((colorInput) => {
        colorInput.addEventListener('change', (event) => {
          // var all = previewDiv.contentDocument.getElementsByTagName("*");
          if (previewDiv.contentDocument.getElementById("page-start-menu-for-js")) {
            previewDiv.contentDocument.querySelectorAll(".btn-link-page-menu").forEach((btn) => {
              btn.style.color = event.currentTarget.value;
            })
          } else {
            var all = previewDiv.contentDocument.querySelectorAll(`.${arrayOfClass.map(el => el.replace('-js', '')).join(", .")}`);
            for (var i=0, max=all.length; i < max; i++) {
              all[i].style.color = event.currentTarget.value;
            }
          }

          // DETAIL_MENU_NEW : a faire à la mano car ne marche la boucle for du dessus ne marche pas sur les tabs:
          previewDiv.contentDocument.querySelectorAll("[role='presentation']:not(.active)").forEach((el) => {el.firstElementChild.setAttribute('style', `color: ${event.currentTarget.value} !important`)})
          if (previewDiv.contentDocument.querySelector(".cat-tabs-js.active a")) {
            previewDiv.contentDocument.querySelector(".cat-tabs-js.active a").setAttribute('style', `color: ${event.currentTarget.value} !important`)
          }

          //DETAIL_MENU_NEW: faire passer les inputs correspondants dans la bonne couleur
          // #TO DO DANIA
          document.getElementById("font_col_tab_active").value = event.currentTarget.value;
          document.getElementById("font_col_tabs").value = event.currentTarget.value;
          document.getElementById("font_col_categ").value = event.currentTarget.value;
          document.getElementById("font_col_descr_categ").value = event.currentTarget.value;
          document.getElementById("font_col_sub_descr").value = event.currentTarget.value;
          document.getElementById("font_col_pdct").value = event.currentTarget.value;
          document.getElementById("font_col_pdct_descr").value = event.currentTarget.value;
          document.getElementById("font_col_container").value = event.currentTarget.value;
          document.getElementById("colorWellFontAll2").value = event.currentTarget.value;
          document.getElementById("colorWellFontAll3").value = event.currentTarget.value;


          //DETAIL_MEAL_MENU: faire passer les inputs correspondants dans la bonne couleur
          document.getElementById("font_col_back_dmeal").value = event.currentTarget.value;
          document.getElementById("font_col_pdct_dmeal").value = event.currentTarget.value;
          document.getElementById("font_col_pdct_descr_dmeal").value = event.currentTarget.value;
          document.getElementById("font_col_sub_descr_dmeal").value = event.currentTarget.value;
          document.getElementById("font_col_title_allergens_dmeal").value = event.currentTarget.value;
          document.getElementById("font_col_allergens_all_dmeal").value = event.currentTarget.value;
          document.getElementById("font_col_price_dmeal").value = event.currentTarget.value;
          document.getElementById("font_col_container_dmeal").value = event.currentTarget.value;
        })
      })

     
      //submit a page: simulate navigation in iFrame when validate customisation_form
      document.querySelectorAll('#submit_start_page_menu, #submit_my_menu, #submit_detail_menu, #submit_detail_meal').forEach((submit) => {
        submit.addEventListener('click', (event) => {
          if (event.currentTarget.id === "submit_start_page_menu") {
            if (previewDiv) {
              previewDiv.contentDocument.querySelector(".menu-link-for-meals").click();
            }
          } else if (event.currentTarget.id === "submit_my_menu") {
           if (previewDiv.contentDocument.querySelector(".custom-btn.btn-mastercateg-menu")) {
              previewDiv.contentDocument.querySelector(".custom-btn.btn-mastercateg-menu").click();
           }
          } else if (event.currentTarget.id === "submit_detail_menu") {
            previewDiv.contentDocument.querySelector("a.btn-open-detail-menu-new").click();
          } else if (event.currentTarget.id === "submit_detail_meal") {
            previewDiv.contentDocument.querySelector("a.btn-return-detail-menu-new").click();
          }
        })
      })


      // NAVIGATION IFRAME : Quand on navigue dans le iframe reinitier 'previewDiv' pour simuler l'inspecteur
      function handleLoadNavMenu() {
				let previewDiv = document.querySelector("iframe#preview");
				console.log("iframe content loaded sAH");
				// alert("loaded");

				//  Enlève le comportement de base de l'autoscroll des liens du menu sur l'iframe
				const links = previewDiv.contentDocument.querySelectorAll('a[href^="#"]');
				if (links) {
					for (let i = 0; i < links.length; i++) {
						links[i].addEventListener('click', function(event) {
							event.preventDefault();
							const targetId = this.getAttribute('href').substring(1);
							const targetElement = previewDiv.contentDocument.getElementById(targetId);
							const yOffset = targetElement.getBoundingClientRect().top + previewDiv.contentWindow.pageYOffset;
							previewDiv.contentWindow.scrollTo({
								top: yOffset,
							});
						});
					}
				}

				//Afficher les bons input et selecteur sur la partie de gauche en fonction de la page menu sur laquelle on est
				document.querySelectorAll(".container-to-customise-pages-menu-js").forEach((div) => {div.classList.add("hidden")})
				if (previewDiv.contentDocument.getElementById("page-start-menu-for-js")) {
					document.getElementById("start-page-menu-page-js").classList.remove("hidden")
				} else if (previewDiv.contentDocument.getElementById("page-my-menu-for-js")) {
					document.getElementById("my-menu-page-js").classList.remove("hidden")
				} else if (previewDiv.contentDocument.getElementById("id-for-custom-menu-js")) {
					document.getElementById("detail-menu-new-page-js").classList.remove("hidden")
				} else if (previewDiv.contentDocument.getElementById("page-detail-meal-for-js")) {
					document.getElementById("detail-meal-menu-page-js").classList.remove("hidden")
				}

				//changement de couleur des éléments dorés
				changeColorOfSeveralElement();

				//DETAIL_MENU_NEW (en back la valeur sera appliquée aussi à DETAIL_MEAL_MENU): couleur des logo New
				const colorNew = document.getElementById("col_tag_new")
				colorNew.addEventListener("change", (event) => {
					console.log(event.currentTarget.value);
					const divToChange = event.currentTarget.dataset.forColor
					previewDiv.contentDocument.querySelectorAll(divToChange).forEach((div) => {
						div.style.backgroundColor = event.currentTarget.value
					})
				})

				//DETAIL_MENU_NEW (en back la valeur sera sera appliquée aussi à DETAIL_MEAL_MENU): couleur des logo Spicy
        const colorSpicy = document.getElementById("col_tag_spicy")
				colorSpicy.addEventListener("change", (event) => {
					console.log(event.currentTarget.value);
					const divToChange = event.currentTarget.dataset.forColor
					previewDiv.contentDocument.querySelectorAll(divToChange).forEach((div) => {
						div.style.color = event.currentTarget.value
					})
				})

				//DETAIL_MENU_NEW (en back la valeur sera sera appliquée aussi à DETAIL_MEAL_MENU): couleur des logo Veggie
				const colorVeggie = document.getElementById("col_tag_veggie")
				colorVeggie.addEventListener("change", (event) => {
					console.log(event.currentTarget.value);
					const divToChange = event.currentTarget.dataset.forColor
					previewDiv.contentDocument.querySelectorAll(divToChange).forEach((div) => {
						div.style.color = event.currentTarget.value
					})
				})


				//DETAIL_MENU_NEW : couleur du trait séparateur avec la navigation des tabs
				const colorSeparator = document.getElementById("col_separator")
				colorSeparator.addEventListener("change", (event) => {
					console.log(event.currentTarget.value);
					const divToChange = event.currentTarget.dataset.forColor
					previewDiv.contentDocument.querySelectorAll(divToChange).forEach((div) => {
						div.style.borderBottomColor = event.currentTarget.value
						div.style.boxShadow = `${event.currentTarget.value} 0px 2px 3px 0px`
					})
				})


				//Hover to be able to inspect element and change element
				let arrayOfClass = ['title-product-detail-menu-for-js', 'categorie-name-js', 'categ-description-menu-js', 'price-large-pdct-js', 'name-pdct-menu-new3-js', 'new-desc-menu-js','back-menu2-js', 'name-pdct-menu-new2-js', 'new-desc-menu-detail-js', 'price-large-pdct-detail-js', 'allergen-title-js', 'allergen-style-new-js', 'content-menu-js', 'individual-price-js', 'price-style-dmeal-js','container-style-dmeal-js','allergen-logos-style-dmeal-js'];

				//INSPECTEUR: quand on hover sur un element il se colorie en bleu, et quand on appuie dessus ca affiche les bons selecteurs sur la partie de gauche
				arrayOfClass.forEach(function(className) {
					const elements = previewDiv.contentDocument.querySelectorAll(`.${className}`)
					if ( elements.length >= 1) {
						elements.forEach((elmt) => {

							//mettre en bleu toutes les categ quand on hover dessus
							elmt.addEventListener("mouseover", (event) => {
								elements.forEach((cat) => {cat.style.backgroundColor = "#e0f0ff"; cat.style.borderRadius = "2px" });
							})
							//enlever le bleu quand on sort du hover
							elmt.addEventListener("mouseout", (event) => {
								elements.forEach((cat) => {cat.style.backgroundColor = ""; cat.style.borderRadius = "2" });
							})

							//afficher les bon input quand on clique sur une des categ
							elmt.addEventListener("click", (event) => {
								document.querySelectorAll(`#${arrayOfClass.join(", #")}`).forEach((el) => {el.classList.add("hidden")})
								document.getElementById(`${className}`).classList.toggle("hidden")
							})
						})
					}
				})

				//  AUTOSCROLL TO PARAMETERS WHEN CLIKING ON ELEMENT IN IFRAME //

				// Fonction permettant de réaliser le scroll auto lorsqu'on click sur un élément
				const scrollToParameterOnMenuClick = (surveyElement, parameterElement) => {
					if (surveyElement) {
						surveyElement.addEventListener('click', () => {
							const container = document.querySelector('.container-custom-parameters')
							const yOffset = parameterElement.getBoundingClientRect().top - container.getBoundingClientRect().top + container.scrollTop;
							container.scroll({
								top: yOffset,
								behavior: 'smooth'
							});

							// On ajoute du style aux éléments pour lesquels on ne scroll pas afin d'indiquer quel élément est séléctionné
							if ( parameterElement.classList.contains('scroll-scale-element')) {
								parameterElement.classList.add("scrolling-element");
								setTimeout(() => {
									parameterElement.classList.remove("scrolling-element");
								}, 600);
							}
						})
					}
				}

				// On récupère toutes les classes de chaque élément cliquable de l'iframe + du paramètres correspondant (ils ont la même classe)
				const scrollParams = [
					// Detail_menu_new
					".scroll-category-name",
					".scroll-category-description",
					".scroll-category-subdesc",
					".scroll-product-name",
					".scroll-product-price",
					".scroll-product-description",
					".scroll-product-container",

					// Detail_meal_menu
					".scroll-meal-back-to-menu",
					".scroll-meal-description",
					".scroll-meal-categ-subdesc",
					".scroll-meal-price",
					".scroll-meal-container",
					".scroll-meal-allergen-title",
					".scroll-meal-allergen-name",
					".scroll-meal-allergen-svg",
					".scroll-meal-name",
				];

				// On itère sur chaque élément de l'array scrollParams pour appliquer la fonction scrollToparameterOnSurveyClick
				Array.prototype.forEach.call(scrollParams, (selector) => {
					const iframeElements = previewDiv.contentDocument.querySelectorAll(selector);
					const paramElement = document.querySelector(selector);

					Array.prototype.forEach.call(iframeElements, (element) => {
						scrollToParameterOnMenuClick(element, paramElement);
					})
				});
			}

      $('iframe#preview').on('load', handleLoadNavMenu);
			setTimeout(handleLoadNavMenu, 0)

			// var iframe = document.querySelector("iframe");

			// if (iframe) {
      //   var iframeSrc = iframe.src;
      //   iframe.src = '';

      //   setTimeout(function() {
      //       iframe.src = iframeSrc;

      //       iframe.onload = function() {
      //           handleLoadNavMenu();
      //       }
      //   }, 0);
      // }

      // TOUT CE QUI EST RELATIF AUX INPUTS "POLICE" DU CUSTOM MENU
      manageFonts('menu');

      logoBkgColorMenu();

      manageFlagsMenu();

      manageTagsMenu();


      // MY MENU PAGE : Changer la couleur de la flèche de retour : simulation dans l'iframe
      function changeReturnArrowColor(returnArrowColorPicker, returnArrow) {
        const colReturnArrow = document.getElementById(returnArrowColorPicker)
        colReturnArrow.addEventListener("change", (event) => {
          const selectArrow = previewDiv.contentDocument.querySelectorAll(`.${returnArrow}`)
          selectArrow.forEach((elem) => {
            elem.style.setProperty('color', event.currentTarget.value, 'important')
          })
        })
      }

      changeReturnArrowColor('return_arrow_col_mm', 'return_arrow_col_mm')
      changeReturnArrowColor('return_arrow_col_dmn', 'return_arrow_col_dmn')
      changeReturnArrowColor('return_arrow_col_dmm', 'return_arrow_col_dmm')


      // DETAIL_MEAL_MENU : Changer la couleur des logos allergènes => Simulation dans l'iframe
      const svgAllergenColorSelector = document.getElementById('logo_col_allergens_all_dmeal')
      if (svgAllergenColorSelector) {
        svgAllergenColorSelector.addEventListener("change", (event) => {
          previewDiv.contentDocument.querySelectorAll(".allergen-logos-style-dmeal-js").forEach((logo) => {
            logo.style.setProperty('fill', event.currentTarget.value, 'important');
          })
        })
      }
	}
}

export {customMenu}
