import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["startDatePicker", "endDatePicker", "startDate", "endDate", "deleteAllSurveys", "deleteSurveysByDate", "btnValider", "datePickerContainer"]

    connect() {
        this.toggleDatePickers();
    }

    toggleDatePickers() {
        if (this.deleteSurveysByDateTarget.checked) {
          deleteByDateForm
            this.datePickerContainerTarget.classList.remove('hidden');
            this.startDatePickerTarget.disabled = false;
            this.endDatePickerTarget.disabled = false;
            this.updateBtnValiderState();
        } else {
            this.datePickerContainerTarget.classList.add('hidden');
            this.startDatePickerTarget.disabled = true;
            this.endDatePickerTarget.disabled = true;

            if (!this.deleteAllSurveysTarget.checked) {
                this.btnValiderTarget.disabled = true;
                this.btnValiderTarget.classList.add('not-clickable');
            }
        }
    }

    areDatesSelected() {
        return this.startDatePickerTarget.value && this.endDatePickerTarget.value;
    }

    updateBtnValiderState() {
        if (this.deleteAllSurveysTarget.checked || (this.deleteSurveysByDateTarget.checked && this.areDatesSelected())) {
            this.btnValiderTarget.disabled = false;
            this.btnValiderTarget.classList.remove('not-clickable');
        } else {
            this.btnValiderTarget.disabled = true;
            this.btnValiderTarget.classList.add('not-clickable');
        }
    }

    handleDeleteAllSurveysChange() {
        if (this.deleteAllSurveysTarget.checked) {
            this.deleteSurveysByDateTarget.checked = false;
        }
        this.updateBtnValiderState();
        this.toggleDatePickers();
    }

    handleDeleteSurveysByDateChange() {
        if (this.deleteSurveysByDateTarget.checked) {
            this.deleteAllSurveysTarget.checked = false;
        }
        this.updateBtnValiderState();
        this.toggleDatePickers();
    }

    handleBtnValiderClick() {
        if (this.deleteAllSurveysTarget.checked) {
            if (confirm("Êtes-vous sûrs de vouloir supprimer toutes les enquêtes ?")) {
                this.element.querySelector("#deleteForm").submit();
            }
        } else if (this.deleteSurveysByDateTarget.checked) {
            if (this.areDatesSelected()) {
                if (confirm("Êtes-vous sûrs de vouloir supprimer ces enquêtes ?")) {
                    this.element.querySelector("#deleteByDateForm").submit();
                }
            } else {
                alert('Veuillez sélectionner une plage de dates.');
            }
        }
    }
}
