import { Controller } from 'stimulus';
export default class extends Controller {
  connect() {
    console.log("check_enter_js")
  }

  async update_nb_surveys_date_filter() {
    const start_date = document.querySelector("#startDatePicker");
    const end_date = document.querySelector("#endDatePicker");
    console.log("update_nb_surveys_date_filter");
    console.log(start_date.dataset.url)
    const response = await fetch(start_date.dataset.url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
      body: JSON.stringify({ start_date: start_date.value, end_date: end_date.value })
    })
    const data = await response.json();
    this.updateCountSurveys(data);
  };

  updateCountSurveys(jsonData) {
    const newSurveysCountPerDate = jsonData.destroy_surveys_per_date
    const valueSurveysPerDate = document.getElementById('surveys-destroy-count');
    console.log(newSurveysCountPerDate)
    console.log(valueSurveysPerDate)
    valueSurveysPerDate.dataset.unsentNbSurveysScope = newSurveysCountPerDate;
    valueSurveysPerDate.innerHTML = "<strong>" + newSurveysCountPerDate + "</strong>&nbspsurveys";
  };

}
