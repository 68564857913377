import {execJSextra, execJSall} from "../packs/index.js"


// NAVBAR CACHING SYSTEM
// on charge la navbar une première fois, puis on la sauvegarde en cache.
// à chaque page, si la navar est en cache, on affiche la navbar du chache, sinon, on lance fetchNavbar()
// à chaque changement sur la nabar (input), on sauvegarde l'état de chaque input, afin de rendre la même version de la navbar avec les mêmes inputs

function updateNavbarStateCache() {
	// Save states of inputs
	const inputs = document.querySelectorAll('#navbar_ancestry_loop input');
	const states = Array.from(inputs).map(input => ({
			id: input.id,
			checked: input.checked,
			value: input.value
	}));
	localStorage.setItem('navbar_ancestry_loop_states', JSON.stringify(states));
}

async function loadNavbar() {
	if (document.querySelector(".navbar_v6")) {
		const moduleName = document.body.dataset.module;
		const from_e_reputation_module = localStorage.getItem('from_e_reputation_module');

		if (moduleName && moduleName != "e_reputation" && from_e_reputation_module && from_e_reputation_module == 'true'){
			// CASE 1: comming from e_reputation module -> reset the navbar
			console.log("from_e_reputation_module", from_e_reputation_module)
			console.log("HEREHE", from_e_reputation_module)
				
			clearNavbarCache();
			await fetchNavbar();
			localStorage.removeItem('from_e_reputation_module')
		
		} else {
			if (sessionStorage.getItem('reset_navbar_cache') === 'true') { 
				// CASE 2: reset_navbar_cache
				clearNavbarCache();
				await fetchNavbar(); // Then fetch the full navbar
			} else {
				// CASE 3: navbar is in cache, we fetch it
				const navbar = localStorage.getItem('navbar_ancestry_loop');
				const navbar_group_id = localStorage.getItem('navbar_group_id');
				const groupId = document.querySelector(".data-current-group").dataset.group;
				console.log("navbar_group_id", navbar_group_id, "groupId", groupId)
				console.log("navbar && (navbar_group_id == groupId)", navbar && (navbar_group_id == groupId))
				if (navbar && (navbar_group_id == groupId)) {
					document.getElementById('navbar_ancestry_loop').innerHTML = navbar;
					const states = JSON.parse(localStorage.getItem('navbar_ancestry_loop_states'));
					if (states) {
						states.forEach(state => {
							const element = document.getElementById(state.id);
							if (element) {
									element.checked = state.checked;
									element.value = state.value;
							}
						});
					}
				} else {
					// CASE 4: None of the above means no navbar in cache, we build and store it
					await fetchNavbar(); // Then fetch the full navbar
				}
			}
		}

		navigateV6();
		changeParentSgCheckbox();
		highlightSearchNavBar();
		navigationSelectionDisplay();
		blockFromSubmissionOnEnter();
		collaspeDropDowns();
	}
}

async function fetchNavbar() {
	const groupId = document.querySelector(".data-current-group").dataset.group;
	try {
			const response = await fetch(`/groups/${groupId}/navbar_ancestry_loop`);
			const html = await response.text();
			localStorage.setItem('navbar_ancestry_loop', html);
			localStorage.setItem('navbar_group_id', groupId);
			document.getElementById('navbar_ancestry_loop').innerHTML = html;
			console.log("fetchNavbar done");
	} catch (error) {
			console.error('Error loading the navbar:', error);
	}
}

function clearNavbarCache() {
	localStorage.removeItem('navbar_ancestry_loop');
	localStorage.removeItem('navbar_ancestry_loop_states');
}
// MAIN FT
// SAVES THE SELECTED SOUS_GROUPES / RESTAURANTS IN CACHE
// SAVES THE DATE / DATE RANGES IN CACHE
// SAVES THE INDICATORS IN CACHE
// REPLACE THE MAIN FRAME WITH THE NEW DATA
function navigateV6() {
	//multisite
	const forms = document.querySelectorAll(".form-nav-multisite");
	const $data = $("#data")
	if (forms.length > 0) {
		forms.forEach(function(form) {
			form.querySelector(".submit-btn").addEventListener("click", async function(event) {
				event.preventDefault();
				const moduleName = document.body.dataset.module;
				// determine filter type (ancestry / date / indicators )
				let filterType;
				if (form.classList.contains("nav-ancestry")) {
					filterType = "ancestry"
				} else if (form.classList.contains("nav-date")) {
					filterType = "date"
				} else if (form.classList.contains("nav-indicators")) {
					filterType = "indicators"
				}

				// Save html in cache
				updateNavbarStateCache();
				// 2 cases: 
				// 1) navigation within the e-reputation module
				// generate a new url = current url + "?sg_ids=" + "&restaurants_ids="
				// then navigate to this url.
				if (moduleName && moduleName === 'e_reputation') {

					// RESTAURANTS FILTER
					if (filterType == "ancestry") {
						$('.left.side-content').click();
						navigationSelectionDisplay();
						const sousGroupesH = generateSousGroupeH();
						const selectedSousGroupes = findCheckedSousGroupes(sousGroupesH, "id").join(', ');
						const selectedRestaurants = getCheckedRestaurantsNotInCheckedSousGroupes(findCheckedSousGroupes(sousGroupesH, "name"), sousGroupesH).join(', ');
						console.log("selectedSousGroupes", selectedSousGroupes)
						console.log("selectedRestaurants", selectedRestaurants)

						const url = new URL(window.location.href);

						// Create or update the `sg_ids` query parameter (Sous Groupes)
						url.searchParams.set('sg_ids', selectedSousGroupes);

						// Create or update the `restaurant_ids` query parameter (Restaurants)
						url.searchParams.set('restaurant_ids', selectedRestaurants);

						// Replace the current URL with the updated one
						console.log("url", url)
						window.location.href = url.toString();  // This will reload the page
					}

					// no date / indicators filter in e_reputation module

				} else {
					// 2) every other cases 
					let bodyH;
					let paramsEnquete;
					let paramsEnqueteRetraite;

					// RESTAURANTS FILTER
					if (filterType == "ancestry") {
						$('.left.side-content').click();
						navigationSelectionDisplay();
						const sousGroupesH = generateSousGroupeH();
						const selectedSousGroupes = findCheckedSousGroupes(sousGroupesH, "id").join(', ');
						const selectedRestaurants = getCheckedRestaurantsNotInCheckedSousGroupes(findCheckedSousGroupes(sousGroupesH, "name"), sousGroupesH).join(', ');
						console.log("selectedSousGroupes", selectedSousGroupes)
						console.log("selectedRestaurants", selectedRestaurants)

						// When ancestry change is made outside of e_reputation module -> update all e_reputation module's url with the selection
						updateEReputationModuleHref(selectedSousGroupes, selectedRestaurants)

						if (selectedSousGroupes || selectedRestaurants) {
							bodyH = {
								ancestry: true,
								selectedSousGroupes: selectedSousGroupes,
								selectedRestaurants: selectedRestaurants,
							}
						}
					}
					// DATE FILTER
					else if (filterType == "date") {
						$('button.date-filter-btn').click();
						const calendarInput = document.querySelector("#calendar");
						const value = calendarInput.value;
						const dateMatches = value.match(/\d\d\d\d-\d\d-\d\d/g);
						if (dateMatches) {
							const startDate = dateMatches[0];
							const endDate = dateMatches.length > 1 ? dateMatches[1] : null;

							if (endDate) {
							}

							bodyH = {
								date: true,
								startDate: startDate,
								endDate: endDate
							}
						} else {
							// Cumul case.
							const startDate = calendarInput.dataset.startDateCumul;
							const endDate = calendarInput.dataset.endDateCumul;
							bodyH = {
								date: true,
								startDate: startDate,
								endDate: endDate
							}
						}
					}
					// INDICATORS ET ENQUETE FILTER (same form)
					else if (filterType == "indicators") {
						$('button.indicator-filter-btn').click();
						const indicatorH = generateIndicatorsH();
						// const isIndicatorHEmpty = Object.keys(indicatorH).length === 0;
						const groupId = document.querySelector('.nav-indicators').dataset.groupId

						if (groupId === '216') {
							paramsEnquete = []
							paramsEnqueteRetraite = getEnquetes()
						} else {
							paramsEnquete = getEnquetes()
							paramsEnqueteRetraite = []
						}

						bodyH = {
							indicators: true,
							indicators_detail: indicatorH,
						};
					}

					await fetch('/set_filter', {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
							'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
						},
						body: JSON.stringify(bodyH),
					});



					// loading dots on the page
					// if (((event.detail.url.pathname == window.location.pathname) && (event.detail.url.search.match(/frame/))) || (event.target.id == "data")) {
					// if ((event.detail.url.pathname == window.location.pathname) && (event.detail.url.search.match(/frame/))) {

						if ($data) {
							$data.addClass("loading");
							const loadingDotsContainer = document.querySelector(".dots-container");
							loadingDotsContainer.classList.remove("hidden")
						}
					// }

					$.ajax({
						url: $(form).attr('action'),
						type: 'GET',
						data: {
							enquete: paramsEnquete,
							enquete_retraite: paramsEnqueteRetraite,
							filtered: true,
						},
						// dataType: 'script',  // expecting server to respond with javascript
						success: function(response){
							// The server responded with success.
							$data.html(response);
							$data.removeClass("loading");
							const loadingDotsContainer = document.querySelector(".dots-container");
							loadingDotsContainer.classList.add("hidden")
							execJSall();
							execJSextra();
						},
						error: function(xhr, status, error){
							// handle error
							console.log("AJAX error: " + status + " : " + error);
						}
					});
				}

			})
		})
	}
  handleRotateArrowImg()
}

function findCheckedSousGroupes(sousGroupesH, retFormat) {

	let checkedSousGroupes = [];
	for (let sg in sousGroupesH) {

		if (sousGroupesH[sg].checked) {
			let superGroup = Object.keys(sousGroupesH).find(otherSg =>
				sousGroupesH[otherSg].checked && sousGroupesH[otherSg]['sub-sous-groupes'].includes(sg)
				);
				if (!superGroup) {
					if (retFormat == 'name') {checkedSousGroupes.push(sg);} else if (retFormat == 'id') {checkedSousGroupes.push(sousGroupesH[sg].id);}
				}
			}
		}
	return checkedSousGroupes;
}

function hasCheckedRestaurantNotInCheckedSousGroupes(checkedSousGroupes, sousGroupes) {
	let restaurantsInCheckedSousGroupes = new Set();
	checkedSousGroupes.forEach(function(sg) {
			sousGroupes[sg]['checked-restaurants'].forEach(function(restaurant) {
					restaurantsInCheckedSousGroupes.add(restaurant[0]);
			});
	});

	for (let sg in sousGroupes) {
			if (!checkedSousGroupes.includes(sg)) {
					if (sousGroupes[sg]['checked-restaurants'].some(restaurant => !restaurantsInCheckedSousGroupes.has(restaurant[0]))) {
							return true;
					}
			}
	}
	return false;
}


function getAllUniqueCheckedRestaurants(sousGroupes) {
	let allCheckedRestaurants = [];
	for (let sg in sousGroupes) {
			allCheckedRestaurants = allCheckedRestaurants.concat(Array.from(sousGroupes[sg]['checked-restaurants']).map(resto => resto[0]));
	}
	let uniqueCheckedRestaurants = [...new Set(allCheckedRestaurants)];
	return uniqueCheckedRestaurants;
}

function getCheckedRestaurantsNotInCheckedSousGroupes(checkedSousGroupes, sousGroupes) {
	let restaurantsInCheckedSousGroupes = [];

	// Get all the checked restaurants that are in checked sous groupes
	checkedSousGroupes.forEach(function(sg) {
		restaurantsInCheckedSousGroupes = restaurantsInCheckedSousGroupes.concat(sousGroupes[sg]['checked-restaurants']);
	});

	let checkedRestaurantsNotInCheckedSousGroupes = [];

	for (let sg in sousGroupes) {
		if (!checkedSousGroupes.includes(sg)) {
			for (let i = 0; i < sousGroupes[sg]['checked-restaurants'].length; i++) {
				// If an establishment is checked and is not in a checked sous groupe, add it to the result array
				if (!restaurantsInCheckedSousGroupes.includes(sousGroupes[sg]['checked-restaurants'][i])) {
					checkedRestaurantsNotInCheckedSousGroupes.push(sousGroupes[sg]['checked-restaurants'][i][1]);
				}
			}
		}
	}

	return checkedRestaurantsNotInCheckedSousGroupes;
}

function allOrNoneChecked(sousGroupesH, format = null) {
	let totalRestaurants = 0;
	let checkedRestaurants = 0;

	// Iterate over each sous-groupe and each restaurant in it.
	for (let sg in sousGroupesH) {
		totalRestaurants += sousGroupesH[sg]['count-restaurants'];
		checkedRestaurants += sousGroupesH[sg]['checked-restaurants'].length;
	}

	if (format && format == "all") {
		return checkedRestaurants === totalRestaurants;
	} else if (format && format == "all") {
		return checkedRestaurants === 0;
	} else {
		return checkedRestaurants === 0 || checkedRestaurants === totalRestaurants;
	}
}

function generateSousGroupeH() {
	const sousGroupes = document.querySelectorAll(".select-multisite.sous-groupe-nav");
	const sousGroupesH = {};

	sousGroupes.forEach((sg) => {
		const sgCheckbox = sg.querySelector(".sg-checkbox-nav");
		sousGroupesH[sgCheckbox.dataset.name] = {};
		sousGroupesH[sgCheckbox.dataset.name]["id"] = sgCheckbox.dataset.sgId;
		sousGroupesH[sgCheckbox.dataset.name]["checked"] = sgCheckbox.checked;

		sousGroupesH[sgCheckbox.dataset.name]["checked-restaurants"] = Array.from(sg.querySelectorAll("input[type='checkbox']:checked.restaurant-checkbox-nav")).map(resto => [resto.getAttribute('data-name'), resto.getAttribute('data-restaurant-id')])
		sousGroupesH[sgCheckbox.dataset.name]["count-restaurants"] = sg.querySelectorAll("input[type='checkbox'].restaurant-checkbox-nav").length
		sousGroupesH[sgCheckbox.dataset.name]["sub-sous-groupes"] = Array.from(sg.querySelectorAll(".select-multisite.sous-groupe-nav")).map(subsg => subsg.querySelector("input[type='checkbox'].sg-checkbox-nav").getAttribute('data-name'))
	})

	return sousGroupesH
}

function navigationSelectionDisplay() {
	const sousGroupesH = generateSousGroupeH();
	const displayDiv = document.querySelector(".left.side-content")

	if (displayDiv) {

		let checkedSousGroupes = findCheckedSousGroupes(sousGroupesH, "name");
		let hasRestaurantOutside = hasCheckedRestaurantNotInCheckedSousGroupes(checkedSousGroupes, sousGroupesH);
		let uniqueRestaurants = getAllUniqueCheckedRestaurants(sousGroupesH);
    let sousGroupesNamesWithRestaurantsSelected = fetchSousGroupeNamesWithRestaurantsSelected(sousGroupesH)

		let string = "";
		if (checkedSousGroupes.length == 1 && !hasRestaurantOutside) {
			string = `${checkedSousGroupes[0]} (${uniqueRestaurants.length})`;
		} else if (checkedSousGroupes.length == 0 && uniqueRestaurants.length == 1) {
      string = `${uniqueRestaurants[0]} (${uniqueRestaurants.length})`;
		} else if (allOrNoneChecked(sousGroupesH) && document.querySelector(".site-name")){
      string = `${document.querySelector(".site-name").innerText} (${uniqueRestaurants.length})`;
		} else if (uniqueRestaurants.length > 0 && sousGroupesNamesWithRestaurantsSelected.length == 1) {
      string = `${sousGroupesNamesWithRestaurantsSelected[0]} (${uniqueRestaurants.length})`;
		} else if (uniqueRestaurants.length > 0) {
      string = `${document.querySelector(".site-name").innerText} (${uniqueRestaurants.length})`;
    }
		displayDiv.querySelector(".group-name-for-js").innerText = string
	}
}

function fetchSousGroupeNamesWithRestaurantsSelected(sousGroupes) {
  let result = []

  for (let sg in sousGroupes) {
    if (sousGroupes[sg]['checked-restaurants'].length > 0) {
      result.push(sg)
    }
  }

  return result
}

function changeParentSgCheckbox() {

  const $formNavMultisite = $(".form-nav-multisite");
  if ($formNavMultisite) {
    const $checkboxes = $formNavMultisite.find("input[type='checkbox']:not(#select-all)")
    const selectAll = document.getElementById("select-all");

    $checkboxes.each(function(index, elem) {
      $(elem).on('change', function() {
        const closestSousGroupe = elem.closest(".select-multisite.sous-groupe-nav")
        recursiveCheckboxes(elem, closestSousGroupe)
        $(selectAll).prop("checked", allOrNoneChecked(generateSousGroupeH(), "all"))
      });
    });

    $(selectAll).on('change', function() {
      $("#navigation-multisite").find("input[type='checkbox']").prop("checked", selectAll.checked)
    })
  }
}

function recursiveCheckboxes(elem, closestSousGroupe) {
  if(closestSousGroupe) {
	  const sousGroupeCheck = closestSousGroupe.querySelector('.sg-checkbox-nav')
    if (elem == sousGroupeCheck) {
      closestSousGroupe.querySelectorAll("input[type='checkbox']").forEach((cb)=> {
        $(cb).prop("checked", elem.checked)
      })
    } else {
      if (closestSousGroupe.querySelectorAll("input[type='checkbox']:checked").length == (closestSousGroupe.querySelectorAll("input[type='checkbox']").length - 1) && sousGroupeCheck.checked == false)  {
        $(sousGroupeCheck).prop("checked", true)
      } else {
        $(sousGroupeCheck).prop("checked", false)
      }
    }
    if (!closestSousGroupe.parentNode.classList.contains("navigation-list-container")) {
      recursiveCheckboxes(sousGroupeCheck, closestSousGroupe.parentNode.closest(".select-multisite.sous-groupe-nav"))
    }
  }
}


function highlightSearchNavBar() {
	$('#search-top-navbar').on('keyup', function(e){
		var query = e.target.value.toLowerCase();
		var options = {
			"element": "span",
			"className": "highlight",
			"separateWordSearch": false
		};

		$('.sous-groupe-nav, .restaurant-nav').unmark({
			done: function() {
				$('.sous-groupe-nav, .restaurant-nav').mark(query, options);
				if (query.length > 0) {
          var firstMatch = $('.highlight:first');
          if (firstMatch.length) {
						$('.navigation-list-container').scrollTop(0)
            var position = firstMatch.offset().top - $('.navigation-list-container').offset().top;
						setTimeout(function() {
            	$('.navigation-list-container').animate({ scrollTop: position }, 200);
						}, 0)
          }
        }
			}
		});

		// Open dropdown sections for matching sous-groupes or restaurants
		$(".dropdown-content-nav").each(function(){
			let show = false;
			$(this).find('.sous-groupe-nav, .restaurant-nav').each(function(){
				var itemName = $(this).text().toLowerCase();
				if (query != "" && itemName.includes(query)) {
					show = true;
				}
			});
			if (show) {
				$(this).closest(".dropdown-content-nav").addClass("show")
			}
			// else {
			// 	$(this).closest(".dropdown-content-nav").removeClass("show")
			// }
		})
	});
}


function blockFromSubmissionOnEnter() {
	const $form = $('#search-top-navbar')
	if ($form) {
		$form.keydown(function(e) {
			if (e.key === 'Enter' || e.keyCode === 13) {
					e.preventDefault();
					return false;
			}
		});
	}
}

function generateIndicatorsH() {
	const tagsCheckboxes = document.querySelectorAll(".indicator-checkbox-nav");
	const indicatorH = {};

	tagsCheckboxes.forEach((tag) => {
		if (tag.checked && tag.dataset.indicator) {
			if (!indicatorH[tag.dataset.indicator]) {indicatorH[tag.dataset.indicator] = []};
			indicatorH[tag.dataset.indicator].push(tag.id);
		}
	})

	return indicatorH
}

function getEnquetes() {
  const tagsCheckboxes = document.querySelectorAll(".indicator-checkbox-nav");
  const result = []

  tagsCheckboxes.forEach((tag) => {
    if (tag.checked && tag.dataset.enqueteName) {
      result.push(tag.dataset.enqueteName);
    }
  })

  return result
}


function collapseOneDropDown(dropdownSelector, btnSelector) {
	const dropdown = document.getElementById(dropdownSelector);
	const dropdownBtn = document.querySelector(btnSelector);
	if (dropdown) {
		document.addEventListener('click', function(e) {
			// If the target of the click is not the dropdown or a descendant of the dropdown
			if (!dropdown.contains(e.target) && (dropdown.classList.contains('show'))) {
				// Collapse the dropdown
				$(dropdownBtn).click()
				console.log('dropdown_btn', $(dropdownBtn))
        resetRotateArrowImg(dropdownBtn)
			}
		});

		dropdown.addEventListener('click', function(e) {
			// Stop the propagation of the click event to the document
			e.stopPropagation();
		});
	}
}


function collaspeDropDowns() {
	// NAV ANCESTRY
	collapseOneDropDown('navigation-multisite', '.left.side-content')
	// NAV DATE
	collapseOneDropDown('date-filter-dd', 'button.date-filter-btn')
	// NAV INDICATORS
	collapseOneDropDown('incidator-filter-dd', 'button.indicator-filter-btn')
}

function handleRotateArrowImg() {
  $('.left.side-content').click(function() {
    $(this).find('.topbar-arrowdown').toggleClass('down')
  })
}

function resetRotateArrowImg(div) {
  const $arrow = $(div).find('.topbar-arrowdown')

  if($arrow) {
    $arrow.removeClass('down')
  }
}

// update all elements with `data-e_reputation_id` (i.e. all the e_reputation module's urls within the sidebar)
function updateEReputationModuleHref(sg_ids, restaurant_ids) {
  document.querySelectorAll('a[data-e_reputation_id]').forEach(function (anchor) {
    let url = new URL(anchor.href); 
    
    if (sg_ids) {
      url.searchParams.set('sg_ids', sg_ids); 
    }

    if (restaurant_ids) {
      url.searchParams.set('restaurant_ids', restaurant_ids); 
    }

    anchor.href = url.toString();
  });
}

export { loadNavbar }//navigateV6, changeParentSgCheckbox, highlightSearchNavBar, navigationSelectionDisplay, blockFromSubmissionOnEnter, collaspeDropDowns }
